import React, { useState,Component } from 'react';
import { Modal, Button } from "react-bootstrap";
import info from '../img/ISSUAA-i.png';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "bootstrap/dist/css/bootstrap.css";
import axios from "axios";
import Zoom from '@successtar/react-reveal/Zoom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import arrowDown from '../img/arrow-down.png';
import { Check } from 'react-bootstrap-icons';
import { ChevronDown } from 'react-bootstrap-icons';



class GovernanceTokenPage extends Component {
    constructor (props) {
        super(props)
        let nowTimeStamp = new Date().getTime()
        
        let endDateTimeStamp = nowTimeStamp + (4 * 365 * 86400*1000)
        let maxDate = new Date(endDateTimeStamp)
        let chains = [['maticTestnet', 10009],['bscTestnet', 10002]]
        this.state = {
          startDate: new Date(),
          maxDate: maxDate,
        };
        this.setDate = this.setDate.bind(this);
        this.submitDate = this.submitDate.bind(this);
        
  }

  setDate(date) {
    this.setState({
      startDate: date
    })
    this.setState({newLockTime: date.getTime()/1000})
    }

  submitDate(e) {
    e.preventDefault();
    let main = this.state.startDate
    console.log(main.format('L'));
  }



    state = { 
        assets: ['wait'],
        modalOpen: false,
        selectedAsset : '',

        vestingSchedule: [],
    }
    async componentDidMount() {
        let now = new Date().getTime()/1000
        let testChainData = {}
        testChainData['Mumbai (Polygon testnet)'] = [10009,'0x1AFB455e5431a41f97d37179672377BBa973Fd87']
        testChainData['Binance Smart Chain'] = [10002,'0xb14Aa5a12B9EbdeDD9C18E67dFbb77B1b344B85c']
        testChainData['Fuji (Avalanche testnet)'] = [10006,'0x0A668c3280148b19D892F6C291576E41510f3Db8']
        testChainData['Rinkeby'] = [10001,'0x3154Bc7E5BFC78D90dE3E4Ee91208F056BC5071e']
        testChainData['Arbitrum (Rinkeby testnet)'] = [10010,'0x3154Bc7E5BFC78D90dE3E4Ee91208F056BC5071e']
        testChainData['Fantom (testnet)'] = [10012,'0x684C7183361529CB730D2e2cEeb9bacd70571916']
        let chainData = testChainData
        let testChains = ['Rinkeby','Binance Smart Chain','Fuji (Avalanche testnet)','Mumbai (Polygon testnet)','Arbitrum (Rinkeby testnet)','Fantom (testnet)']
        if (this.props.chain === "rinkebyArbitrum"){  testChains = ['Rinkeby','Binance Smart Chain','Fuji (Avalanche testnet)','Mumbai (Polygon testnet)','Fantom (testnet)']}
        if (this.props.chain === "MaticTestnet"){  testChains = ['Rinkeby','Binance Smart Chain','Fuji (Avalanche testnet)','Arbitrum (Rinkeby testnet)','Fantom (testnet)']}
        if (this.props.chain === "fantomTestnet"){  testChains = ['Rinkeby','Binance Smart Chain','Fuji (Avalanche testnet)','Mumbai (Polygon testnet)','Arbitrum (Rinkeby testnet)']}
        if (this.props.chain === "fuji"){  testChains = ['Rinkeby','Binance Smart Chain','Mumbai (Polygon testnet)','Arbitrum (Rinkeby testnet)','Fantom (testnet)']}
        if (this.props.chain === "rinkeby"){  testChains = ['Binance Smart Chain','Fuji (Avalanche testnet)','Mumbai (Polygon testnet)','Arbitrum (Rinkeby testnet)','Fantom (testnet)']}
        if (this.props.chain === "bscTestnet"){  testChains = ['Rinkeby','Fuji (Avalanche testnet)','Mumbai (Polygon testnet)','Arbitrum (Rinkeby testnet)','Fantom (testnet)']}

        
        let chains = testChains
        this.setState({
            GovernanceTokenBalance: this.props.GovernanceTokenBalance,
            GovernanceTokenStakeBalance: this.props.GovernanceTokenStakeBalance,
            stakeModalOpen: false,
            unstakeModalOpen: false,
            createLockModalOpen: false,
            extendLockModalOpen: false,
            increaseLockModalOpen: false,
            bridgeModalOpen: false,
            chooseChainModalOpen: false,
            now:now,
            selectedChain: 'Select a chain',
            selectedChainID: 0,
            chainData: chainData,
            chains: chains,
            destinationChainBalance: 0,
            destinationChainTargetBalance: this.props.web3.utils.toBigInt(0),
            checkingBridgeResultOnDestinationChain: false,
            bridgingInProcess: false,
            confirmedOnSourceChain: false,
            confirmedOnDestinationChain: false,
            VotingEscrowApproved: false
        });
        this.checkVotingEscrowAllowance()
        
    }
    
    checkVotingEscrowAllowance = async () => {
        if (typeof(this.props.VotingEscrow) != 'undefined'){ 
            // check if the the allowance has been set for the VotingEscrow contract to spend ISS
            let addressToApprove = this.props.VotingEscrow._address
            let allowance = await this.props.GovernanceToken.methods.allowance(this.props.address, addressToApprove).call()
            console.log(allowance)
            if (allowance > 100000000 * 1e18) {
                this.setState({VotingEscrowApproved: true})
            }
            else {
                this.setState({VotingEscrowApproved: false}) 
            } 
            console.log(this.state.VotingEscrowApproved)
        }
    };

    

    listChains() {        
        if (typeof(this.state.chains) != 'undefined'){
            let chainOptions = this.state.chains.map((element,index) =>
                    <li key={index} className="list-group-item selectAssetItem" role="button" onClick={()=>this.selectChain(element)}>
                        <div className="row">
                            <div className="col"><b>{element}</b></div>
                            
                        </div>                        
                    </li>
            );
            return(chainOptions)
        }
        else {return ('')}
    }

    
    

    openCreateLockModal = async () => {
        this.setState({ createLockModalOpen: true })     
    };
    closeCreateLockModal = () => this.setState({ createLockModalOpen: false });

    openExtendLockModal = async () => {
        this.setState({ extendLockModalOpen: true }) 
    };
    closeExtendLockModal = () => this.setState({ extendLockModalOpen: false });

    openIncreaseLockModal = async () => {
        this.setState({ increaseLockModalOpen: true }) 
    };
    closeIncreaseLockModal = () => this.setState({ increaseLockModalOpen: false });

    

    confirmCreateLockTransaction = async() => {
        this.closeCreateLockModal()
        let message = "Transmitting to the blockchain. Waiting for confirmation"
        this.props.openMessageBox(message)
        let amountToLock = document.getElementById('amountToLock').value
        var amountRaw = this.props.web3.utils.toWei(amountToLock.toString(), 'ether')
        var amount = this.props.web3.utils.toBigInt(amountRaw)
        await this.props.transactWithContract('votingEscrow','createLock',[amount,this.state.newLockTime])
        
        await this.props.checkRewards();
    }

    confirmIncreaseLockTimeTransaction = async() => {
        this.closeExtendLockModal()
        let message = "Transmitting to the blockchain. Waiting for confirmation"
        this.props.openMessageBox(message)
        await this.props.transactWithContract('votingEscrow','increaseLockTime',[this.state.newLockTime])
        await this.props.checkRewards();
    }

    confirmIncreaseLockAmountTransaction = async() => {
        this.closeIncreaseLockModal()
        let message = "Transmitting to the blockchain. Waiting for confirmation"
        this.props.openMessageBox(message)
        let amountToLock = document.getElementById('amountToLock').value
        var amountRaw = this.props.web3.utils.toWei(amountToLock.toString(), 'ether')
        var amount = this.props.web3.utils.toBigInt(amountRaw)
        await this.props.transactWithContract('votingEscrow','increaseLockAmount',[amount])
        await this.props.checkRewards();
        
    }

    confirmWithdraw = async() =>{  
        console.log("Withdrawing ISS",this)
        let message = "Withdrawing ISS from staking"
        this.props.openMessageBox(message)
        await this.props.transactWithContract('votingEscrow','withdraw',[])
        await this.props.checkRewards();
    }

    roundDown = (n,d) => {
        n = Math.floor(n*(10**d))
    
        n = n/(10**d)
        return n
    }

    setMaxLockAmount = async() =>{
        let amount = 0
        if (typeof(this.props.GovernanceTokenBalance) != 'undefined'){ 
            amount = parseFloat(this.props.GovernanceTokenBalance)
        }
        document.getElementById('amountToLock').value = this.roundDown(amount,14);
        return
    }


    approveVestingEscrow = async() =>{  
        console.log("Approving the VotingEscrow contract to spend ISS",this)
        let message = "Approving the VotingEscrow contract to spend ISS"
        this.props.openMessageBox(message)
        const addressTo = this.props.VotingEscrow._address;
        let trx_status = await this.props.approveToken(this.props.GovernanceToken_Address,this.props.VotingEscrow._address)
        return (true)
    };

    claim = async() =>{  
        console.log("Claiming rewards",this)
        let message = "Claiming rewards"
        this.props.openMessageBox(message)
        console.log(this.props.RewardsMachine)
        let gasPrice = 10000000000;
        try {
          const result = await axios.get("https://gasstation-mainnet.matic.network/");
          console.log(result.data);
          gasPrice = (parseInt(result.data['standard'])+2)*1000000000;
          console.log(gasPrice)
        } 
        catch (error) {
          console.error(error);
        }
        try{await this.props.RewardsMachine.methods.claimRewards().send({from: this.props.address, gasPrice: gasPrice})}
        catch(e){
            console.log(e['message'])
            message = e['message']
            this.props.openMessageBox(message)
            await this.props.sleep(5000)
            this.props.closeMessageBox();
            return (false)
        }   
        await this.props.checkRewards();
        this.props.closeMessageBox()
    };

    createRewards = async() =>{  
        console.log("Creating rewards",this)
        let message = "Minting the weekly rewards pool"
        this.props.openMessageBox(message)
        console.log(this.props.RewardsMachine)
        let gasPrice = 10000000000;
        try {
          const result = await axios.get("https://gasstation-mainnet.matic.network/");
          console.log(result.data);
          gasPrice = (parseInt(result.data['standard'])+2)*1000000000;
          console.log(gasPrice)
        } 
        catch (error) {
          console.error(error);
        }
        let result = await this.props.RewardsMachine.methods.createRewards().send({from: this.props.address, gasPrice: gasPrice})
        console.log(result)
        
        await this.props.checkRewards();
        this.props.closeMessageBox()
    };


    render() { 
        const tooltip1 = props => (
            <Tooltip {...props}>
            ISS tokens must be staked and locked in order to participate in governance votings.
            </Tooltip>
        );

        const tooltip3 = props => (
            <Tooltip {...props}>
            Balance of liquid (i.e. not locked) ISS available for trading, providing liquidity, a.o.
            </Tooltip>
        );
        const tooltip4 = props => (
            <Tooltip {...props}>Amount of ISS currently outstanding</Tooltip>
        );
        const tooltip5 = props => (
            <Tooltip {...props}>
            The maximum amount of ISS is limited at 100m. All ISS tokens are pre-mineds.
            </Tooltip>
        );
        const tooltip6 = props => (
            <Tooltip {...props}>
            Total value of ISS circulating, i.e.: Amount of ISS currently outstanding multiplied by current ISS price.
            </Tooltip>
        );
        const tooltip7 = props => (
            <Tooltip {...props}>
            Max. possible supply of ISS (@100m) multiplied by current ISS price.
            </Tooltip>
        );

        const tooltip10 = props => (
            <Tooltip {...props}>
            Claim your weekly ISS rewards for liquidity providing and participating in governance votes here (Function only available if rewards were earned).
            </Tooltip>
        );
        
        
        return ( 
            



            <div className="container-fluid w-100">


                <Modal show={this.state.createLockModalOpen} onHide={this.closeCreateLockModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Lock ISS Tokens</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <label className="input-group-text" for="amountToLock">Amount to lock:</label>
                            </div>
                            <input className="form-control border" type='number' id='amountToLock'></input>
                            <div className="input-group-append">
                                <div onClick={this.setMaxLockAmount} role="button" className="badge badge-accent" for="amountToUnStake">Max</div>
                            </div>
                        </div>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <label className="input-group-text" for="lockDate">Lock until:</label>
                            </div>
                            <div className="input-group-append border" id="lockDate">
                                <DatePicker
                                  selected={ this.state.startDate }
                                  maxDate={this.state.maxDate}
                                  onChange={ this.setDate }
                                  name="startDate"
                                  dateFormat="MM/dd/yyyy"
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {(this.state.VotingEscrowApproved === false
                            ?
                            <Button variant="fuchsia text-black w-100" id="buttonRounded" onClick={this.approveVestingEscrow}>Approve</Button>
                            :
                            ''
                        )}
                        <Button variant="fuchsia text-black w-100" id="buttonRounded" onClick={this.confirmCreateLockTransaction}>Lock</Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.extendLockModalOpen} onHide={this.closeExtendLockModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Extend the lock time of your ISS Tokens</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <label className="input-group-text" for="lockDate">Lock until:</label>
                            </div>
                            <div className="input-group-append border" id="lockDate">
                                <DatePicker
                                  selected={ this.state.startDate }
                                  maxDate = {this.state.maxDate}
                                  onChange={ this.setDate }
                                  name="startDate"
                                  dateFormat="MM/dd/yyyy"
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="fuchsia text-black w-100" id="buttonRounded" onClick={this.confirmIncreaseLockTimeTransaction}>Extend lock time</Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.increaseLockModalOpen} onHide={this.closeIncreaseLockModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Increase the amount of locked ISS Tokens</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <label className="input-group-text" for="amountToLock">Amount to lock:</label>
                            </div>
                            <input className="form-control border" type='number' id='amountToLock'></input>
                            <div className="input-group-append">
                                <div onClick={this.setMaxLockAmount} role="button" className="badge badge-accent" for="amountToUnStake">Max</div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="fuchsia text-black w-100" id="buttonRounded" onClick={this.confirmIncreaseLockAmountTransaction}>Increase locked amount</Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.bridgeModalOpen} onHide={this.closeBridgeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Bridge ISS tokens to another chain</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col">
                                From:
                            </div>
                        </div>
                        <div className="row">
                            <div className="col h5">
                                {this.props.chain}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col w-100 text-right textBalance" onClick={this.setMaxBridgeAmount} role="button" >
                                Balance: <span className="textBalance">{this.props.outputNumber(this.props.GovernanceTokenBalance,0)} (Max)</span>
                            </div>
                        </div>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <label className="input-group-text" for="amountToLock">Amount:</label>
                            </div>
                            <input className="form-control border" type='number' id='amountToBridge'></input>
                            
                        </div>
                        <div className="row">
                            <div className="col">
                                To:
                            </div>
                        </div>
                        <div className="btn my-auto btn-accent mx-2" id="buttonRounded" onClick={this.openChooseChainModal}>
                            <div>{this.state.selectedChain}<img src={arrowDown} alt="switch" height="15"/>   </div>
                        </div>
                        
                        {this.state.selectedChain !='Select a chain'
                        ?
                        <div className="row">
                            <div className="col w-100 text-right textBalance">
                                Balance: <span className="textBalance">{this.props.outputNumber(this.state.destinationChainBalance,0)}</span>
                            </div>
                        </div>
                        :
                        ''
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        {(this.state.bridgingApproved === false
                            ?
                            <Button variant="fuchsia text-black w-100" id="buttonRounded" onClick={this.approveBridge}>Approve</Button>
                            :
                            ''
                        )}
                        {(this.state.bridgingApproved === true && this.state.bridgingInProcess === false
                            ?
                            <Button variant="fuchsia text-black w-100" id="buttonRounded" onClick={this.confirmBridgeTokens}>Bridge tokens</Button>
                            :
                            ''
                        )}
                        {(this.state.bridgingApproved === true && this.state.bridgingInProcess === true && this.state.checkingBridgeResultOnDestinationChain === false && this.state.confirmedOnDestinationChain === false
                            ?
                            <div className="row w-100">
                                <div className="col">
                                    <div className="spinner-border spinner-border-sm" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <div className="col-10">
                                    Waiting for transaction to be confirmed on source chain    
                                </div>
                                
                            </div>
                            :
                            ''
                        )}

                        

                        {(this.state.confirmedOnSourceChain
                            ?
                            <div className="row w-100">
                                <div className="col-2">
                                    <Check className="h2 text-accent"/>
                                </div>
                                <div className="col-10">
                                    Transaction confirmed on source chain    
                                </div>
                                
                            </div>
                            :
                            ''
                        )}

                        {(this.state.confirmedOnDestinationChain
                            ?
                            <div className="row w-100">
                                <div className="col-2">
                                    <Check className="h2 text-accent"/>
                                </div>
                                <div className="col-10">
                                    Transaction confirmed on destination chain    
                                </div>
                                
                            </div>
                            :
                            ''
                        )}

                        

                        {(this.state.bridgingApproved === true && this.state.bridgingInProcess === true && this.state.checkingBridgeResultOnDestinationChain === true
                            ?
                            <div className="row w-100">
                                <div className="col">
                                    <div className="spinner-border spinner-border-sm" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <div className="col-10">
                                    Waiting for transaction to be confirmed on destination chain   
                                </div>
                                
                            </div>
                            :
                            ''
                        )}

                        {(this.state.bridgeResetButtonVisible
                            ?
                            <Button variant="fuchsia text-black w-100" id="buttonRounded" onClick={this.resetBridgeInput}>Bridge more tokens</Button>
                            :
                            ''
                        )}
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.chooseChainModalOpen} onHide={this.closeChooseChainModal}>
                    <Modal.Header className="border" closeButton>
                        <Modal.Title>To chain</Modal.Title>   
                    </Modal.Header>
                    <Modal.Body className="bg-tgrey" style={{
                      maxHeight: 'calc(100vh - 210px)',
                      overflowY: 'auto'
                     }} 
                    >
                        <div className="row p-3 pr-3 my-auto">
                            <input className="col w-100" id="search" placeholder="Search" onChange={() =>this.filterAssets()}></input>
                            
                        </div>
                        <ul className="list-group border border-nav">
                            {this.listChains()}
                        </ul>
                    </Modal.Body>
                        
                    
                </Modal>


                <div className="row">
                    <div className="col"></div>
                    <div className='col-6'>
                        <div 
                            style ={{
                                fontFamily: "PosteramaRegular",
                                letterSpacing: "0.1rem",
                                fontSize: "3.5vw",
                                color:"white",
                                paddingLeft: "2vw",
                                marginBottom: "2vh",
                            }}
                        >
                            ISS token
                        </div>
                        <div id="mainBox" className=" container text-black p-2">
                            <div className="container p-3 text-light">
                                <div className="container px-4 pr-4 py-2">
                                    <div className="bg-innerBox text-black rounded m-2 mt-4 mb-1 p-4" id="innerBox">
                                        <div className="h5 text-fuchsia row">
                                            <div className="col-7 text-black">Liquid ISS balance:</div>
                                            <div className="col-5 text-right text-black">{this.props.outputNumber(parseFloat(this.props.GovernanceTokenBalance),0)}</div>                                             
                                        </div>
                                        <div className="h5 text-fuchsia row">
                                            <div className="col-7 text-black">Vesting ISS balance:</div>
                                            <div className="col-5 text-right text-black">{this.props.outputNumber(parseFloat(this.props.GovernanceTokenTotalBalanceAllChains-this.props.GovernanceTokenBalance),0)}</div>                                             
                                        </div>
                                        <div className="h5 text-fuchsia row bold">
                                            <div className="col-7 text-black bold">Total ISS balance:</div>
                                            <div className="col-5 text-right text-black bold">{this.props.outputNumber(parseFloat(this.props.GovernanceTokenTotalBalanceAllChains),0)}</div>                                             
                                        </div>
                                        
                                        

                                        
                                    </div>

                                    
                                    <div className="bg-innerBox text-black rounded m-2 mb-1 p-4" id="innerBox">
                                        <div className="h5 text-fuchsia row">
                                            <div className="col-7 text-black">Your veISS balance:</div>
                                            <div className="col-5 text-right text-black">{this.props.outputNumber(parseFloat(this.props.veISSBalance),0)}</div>                                             
                                        </div>
                                        <div className="row justify-content-between">
                                            <div className="col-7">
                                                <OverlayTrigger placement="top" overlay={tooltip1}>
                                                    <img className="mr-2" src={info} alt="Logo"/>
                                                </OverlayTrigger>
                                                Current lock ends on 
                                            </div>
                                            <div className="col-4 text-right">
                                                {(this.props.lockDate   > this.state.now
                                                    ?
                                                    this.props.timeStampToDate(this.props.lockDate,0)
                                                    :
                                                    'n.a.'
                                                )}
                                            </div>                                             
                                        </div>
                                        {(this.props.lockDate   < this.state.now && this.props.GovernanceTokenStakeBalance >1
                                            ?
                                            <div className="m-2 pt-3 pb-3">
                                                <button className="btn btn-accent text-black w-100 md-1" id="buttonRounded" onClick={this.confirmWithdraw}>Unlock ISS</button>
                                            </div>
                                            :
                                            ''
                                        )}

                                        {(this.props.GovernanceTokenBalance   >1 && this.props.lockDate   > this.state.now
                                            ?
                                            <div className="ml-2 mr-2 pt-3">
                                                <button className="btn btn-fuchsia text-black w-100 md-0" id="buttonRounded" onClick={this.openIncreaseLockModal}>Increase locked amount</button>
                                            </div>
                                            :
                                            ''
                                        )}

                                        {(this.props.lockDate   <1
                                            ?
                                            <div className="ml-2 mr-2 pt-3">
                                                <button className="btn btn-fuchsia text-black w-100 md-1" id="buttonRounded" onClick={this.openCreateLockModal}>Create a lock</button>
                                            </div>
                                            :
                                            ''
                                        )}

                                        {(this.props.veISSBalance   >0
                                            ?
                                            <div className="ml-2 mr-2 pt-3">
                                                <button className="btn btn-fuchsia text-black w-100 md-1" id="buttonRounded" onClick={this.openExtendLockModal}>Extend lock time</button>
                                            </div>
                                            :
                                            ''
                                        )}
                                        <div className="h5 text-fuchsia row mt-3">
                                            <div className="col-7 text-black">Total veISS supply:</div>
                                            <div className="col-5 text-right text-black">{this.props.outputNumber(parseFloat(this.props.totalVeISSSupply),0)}</div>                                             
                                        </div>
                                    </div>
                                    
                                    

                                    

                                    <div className="bg-innerBox text-black rounded m-2 p-4" id="innerBox">
                                        <div className="row justify-content-between">
                                            <div className="col-7">
                                                <OverlayTrigger placement="top" overlay={tooltip4}>
                                                    <img className="mr-2" src={info} alt="Logo"/>
                                                </OverlayTrigger>
                                                ISS circulating
                                            </div>
                                            <div className="col-4 text-right">{this.props.outputNumber(this.props.ISSSupplyCurrent/1000000,1)}m</div>                                             
                                        </div>

                                        <div className="row justify-content-between">
                                            <div className="col-7">
                                                <OverlayTrigger placement="top" overlay={tooltip5}>
                                                    <img className="mr-2" src={info} alt="Logo"/>
                                                </OverlayTrigger>
                                                ISS total max. supply
                                            </div>
                                            <div className="col-4 text-right">{this.props.outputNumber(100,1)}m</div>                                             
                                        </div>

                                        <div className="row justify-content-between">
                                            <div className="col-7">
                                                <OverlayTrigger placement="top" overlay={tooltip6}>
                                                    <img className="mr-2" src={info} alt="Logo"/>
                                                </OverlayTrigger>
                                                Current ISS market cap
                                            </div>
                                            <div className="col-4 text-right">USDC {this.props.outputNumber(this.props.ISSPrice * this.props.ISSSupplyCurrent/1000000,2)}m</div>                                             
                                        </div>
                                        <div className="row justify-content-between">
                                            <div className="col-7">
                                                <OverlayTrigger placement="top" overlay={tooltip7}>
                                                    <img className="mr-2" src={info} alt="Logo"/>
                                                </OverlayTrigger>
                                                Diluted ISS market cap
                                            </div>
                                            <div className="col-4 text-right">USDC {this.props.outputNumber(this.props.ISSPrice * 100000000/1000000,2)}m</div>                                             
                                        </div>
                                    </div>
                                    


                                    

                                    
                                    
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="col"></div>
                </div>
            </div>

            
         );
    }
}
 
export default GovernanceTokenPage;