import '../styles/mainpage.css';
import portfolio from '../img/icons/Portfolio.png';
import euro from '../img/icons/Euro.png';
import pool from '../img/icons/Pool.png';
import lock from '../img/icons/Euro.png';
import React, { Component } from 'react';
import Zoom from '@successtar/react-reveal/Zoom';


class Mainpage extends Component {
    state = { 
    assetDetails: null,       
    };

    async componentDidMount() {
        
        this.setState({
            NumberOfAssets: 5,
            assets: this.props.assets,
            poolList: '',
            
        });
    };

    render() { 
        let poolOutput = '';
        //console.log(this.props.pools)
        if (typeof(this.props.pools) != 'undefined'){
            console.log(this.props.pools)
            poolOutput = this.props.pools.map((pool,index) =>

                <tr key={index}>
                    <td><div className="firstRow">{pool[0]}</div></td>
                    <td className="text-left">{pool[3]}</td>
                    <td className="text-right">{this.props.outputNumber(pool[10],2)}</td>
                    <td className="text-right">{this.props.outputNumber(parseFloat(pool[9])/1000,0)}</td>
                    <td className="text-right">{this.props.outputNumber(pool[2]/1e18,0)}</td>                                            
                    <td className="text-right">{this.props.outputNumber(pool[2] * parseInt(pool[4]) /parseInt(pool[5]) /1e18,0)}</td>
                    {this.props.farmActive ? <td className="text-right">{this.props.outputNumber(parseFloat(pool[6] * 0.4*100),0)}% - {this.props.outputNumber(parseFloat(pool[6] *100),0)}%</td>:''}
                </tr>
            ); 
        }
        else {
            <div className="spinner-border text-accent m-3" role="status"></div>
        }  
        return ( 

                <div className="w-100 pl-5 pr-5">
                    <div 
                        style ={{
                            fontFamily: "PosteramaRegular",
                            letterSpacing: "0.1rem",
                            fontSize: "3.5vw",
                            color:"white",
                            paddingLeft: "2vw",
                            marginBottom: "2vh",
                        }}
                    >
                        Dashboard
                    </div>
                    <div className="w-100" id="mainBox">
                        <div className="w-100">
                            <div className="col w-100 m-0" id="innerBox">
                                <div className="row infoRow">                                
                                    <div className="col-md-3 h-100 container text-black p-2">
                                        <div className="infoBox">
                                            <div><img className="" src={euro} alt="ISSUAA" height="50px"/></div>
                                            <div className="infoBoxItem">$ {this.props.outputNumber(parseFloat(this.props.ISSPrice),3)>0 ?
                                                this.props.outputNumber(parseFloat(this.props.ISSPrice),3)
                                                :
                                                <span>...</span>
                                                }
                                            </div>
                                            <div className='infoBoxItem2'>ISS Price</div>
                                        </div>
                                    </div>

                                    <div className="col-md-3 h-100 container text-black p-2">
                                        <div className="infoBox">
                                            <div><img className="" src={portfolio} alt="ISSUAA" height="50px"/></div>
                                            <div className="infoBoxItem">{typeof(this.props.assets)!== 'undefined' ? this.props.assets.length*2+1:<span>...</span>}</div>
                                            <div className="infoBoxItem2">Assets</div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 h-100 container text-black p-2">
                                        <div className="infoBox">
                                            <div><img className="" src={lock} alt="ISSUAA" height="50px"/></div>
                                            <div className="infoBoxItem">$ {typeof(this.props.totalLockedValue)!== 'undefined' ? this.props.outputNumber(this.props.totalLockedValue/1e6,2):<span>...</span>}m</div>
                                            <div className="infoBoxItem2">Val. locked</div>
                                        </div>
                                    </div>  
                                    <div className="col-md-3 h-100 container text-black p-2">
                                        <div className="infoBox">
                                            <div><img className="" src={pool} alt="ISSUAA" height="50px"/></div>
                                            <div className="infoBoxItem">$ {typeof(this.props.assets)!== 'undefined' ? this.props.outputNumber(this.props.ISSMarketCapCurrent/1e6,2):<span>...</span>}m</div>
                                            <div className="infoBoxItem2">ISS Mrkt Cap</div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        
                        <div className="row m-2">
                            <div className="col p-3 pl-4 pb-0">
                                <h3 className="text-white">ISSUAA Assets and pools:</h3>
                                <br></br>
                                <div className="">
                                <table className="mainTable w-100">
                                    <thead>
                                        <tr>
                                            <th scope="col">Symbol</th>
                                            <th className="text-left" scope="col">Asset</th>
                                            <th className="text-right" scope="col">Price</th>
                                            <th className="text-right" scope="col">Upper Limit</th>
                                            <th className="text-right" scope="col">Pool liq.(USD)</th>                                            
                                            <th className="text-right" scope="col">Your stake (USD)</th>
                                            {this.props.farmActive ? <th className="text-right" scope="col">APR</th>:''}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {poolOutput}
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
               
             


            
         );
    }
}
 
export default Mainpage;