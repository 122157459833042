import { Routes, Route } from 'react-router-dom';
import Web3, { providers } from 'web3';
import panda from './img/graphics/panda.jpg';
import React, {useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import {USDC_ABI, IDT_ABI, assetFactory_ABI, ERC20_ABI, GovernanceToken_ABI, VoteMachine_ABI,MarketFactory_ABI,MarketRouter_ABI,MarketPair_ABI,RewardsMachine_ABI,DAO_ABI,Upgrader_ABI,VotingEscrow_ABI,MasterChef_ABI} from './config';
import {USDC_Address_b, IDT_Address_b,assetFactory_Address_b,GovernanceToken_Address_b, VoteMachine_Address_b,MarketFactory_Address_b,MarketRouter_Address_b,RewardsMachine_Address_b,DAO_Address_b,Upgrader_Address_b,VotingEscrow_Address_b,MasterChef_Address_b} from './config';
import {USDC_Address_ba, IDT_Address_ba, assetFactory_Address_ba,GovernanceToken_Address_ba, VoteMachine_Address_ba,MarketFactory_Address_ba,MarketRouter_Address_ba,RewardsMachine_Address_ba,DAO_Address_ba,Upgrader_Address_ba, VotingEscrow_Address_ba,MasterChef_Address_ba} from './config';

//import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { configureChains, createConfig, WagmiConfig } from 'wagmi'
import { infuraProvider } from 'wagmi/providers/infura';
import { scroll, scrollSepolia} from 'wagmi/chains'
import { publicProvider } from 'wagmi/providers/public'
import { readContract, prepareWriteContract, writeContract, waitForTransaction } from '@wagmi/core'
import { parseEther } from "viem";
import { useAccount,useChainId, useNetwork,Chain,useConnect } from "wagmi";
import { useContractWrite, usePrepareContractWrite, useSendTransaction, usePrepareSendTransaction } from "wagmi";




import {GovernanceToken_Address_fantomT,GovernanceToken_Address_fuji,GovernanceToken_Address_bscTestnet} from './config';

import Mainpage from './components/Mainpage';
import Navbar from './components/Navbar';
import Footer from './components/Footer'; 
import Factory from './components/Factory';
import Portfolio from './components/Portfolio';
import BurnAssets from './components/BurnAssets';
import MessageBox from './components/MessageBox';
import GovernanceTokenPage from './components/GovernanceTokenPage';
import Market from './components/Market';
import Pool from './components/Pool';
import Governance from './components/Governance';
import Test from './components/Test';
import EasyFarm from './components/EasyFarm';
import Farm from './components/Farm';
import V2Message from './components/V2Message';
import IDTConversion from './components/IDTConversion';
import SidebarElement from './components/Sidebar';
import ChangeChain from './components/ChangeChain';



//const queryClient = new QueryClient();
//const chains = [scroll,scrollSepolia]



function timeStampToDate(_timestamp) {
  let timestamp = parseInt(_timestamp)
  const date = new Date(timestamp * 1000)
  const options = {year: 'numeric', month: 'long', day: 'numeric' };
  let formattedDate = date.toLocaleDateString(options);
  return(formattedDate);
  
};


function timeStampToDateAndTime(_timestamp) {
  let timestamp = parseInt(_timestamp)
  var date = new Date(timestamp * 1000)
  const options = {year: 'numeric', month: 'long', day: 'numeric'}
  let formattedDate = date.toLocaleDateString(options)
  const optionsTime = {hour: '2-digit', minute: '2-digit'}
  let formattedTime
  formattedTime = date.toLocaleTimeString([],optionsTime)
  return(formattedDate+" "+formattedTime)
};

function outputNumber(number, digits){
  number = parseFloat(number) * (10**digits)
  number = Math.round(number) / (10**digits)
  let output = number.toLocaleString('en-US',{minimumFractionDigits: digits})
  return (output)
}

async function sleep(milliseconds) {
return new Promise(resolve => setTimeout(resolve, milliseconds));
}

const roundDown = (n,d) => {
  n = Math.floor(n*(10**d))
  n = n/(10**d)
  return n
}

function App() {
  

  const {
    activeChain,
    chains,
    error,
    isLoading,
    pendingChainId,
    switchNetwork,
  } = useNetwork()
  
  console.log(activeChain)
  
  // State Variables
  const farmActive = false;
  const [activePage, setActivePage] = useState('mainpage');
  const [address,setAddress] = useState();
  const [addressShort,setAddressShort,getAddressShort] = useState();
  const [assetDetails,setAssetDetails] = useState();  
  const [assetFactory,setAssetFactory] = useState();
  const [assetFactory_Address,setAssetFactory_Address] = useState();
  const [assets,setAssets] = useState();  
  const [assetValue,setAssetValue] = useState();  
  const [chainName,setChainName] = useState();
  const [blockchainDataLoaded,setblockchainDataLoaded] = useState(false);
  const [connector, setConnector] = useState({});
  const [farms,setFarms] = useState();
  const [farmsValue,setFarmsValue] = useState();
  const [fullProtocolIntegration,setFullProtocolIntegration] = useState(false);
  const [DAO_Address,setDAO_Address] = useState();
  const [expiredAssets,setExpiredAssets] = useState();
  const [GovernanceToken,setGovernanceToken] = useState();
  const [GovernanceToken_Address,setGovernanceToken_Address] = useState();
  const [GovernanceTokenBalance,setGovernanceTokenBalance] = useState();
  const [GovernanceTokenTotalBalance,setGovernanceTokenTotalBalance] = useState();
  const [GovernanceTokenTotalBalanceAllChains,setGovernanceTokenTotalBalanceAllChains] = useState();
  const [GovernanceTokenStakeBalance,setGovernanceTokenStakeBalance] = useState();
  const [GovernanceTokenVestedStakeBalance,setGovernanceTokenVestedStakeBalance] = useState();
  const [IDT_nm,setIDT_nm] = useState();
  const [IDTBalance,setIDTBalance] = useState();
  const [IDTBalanceWei,setIDTBalanceWei] = useState();
  const [ISSPrice,setISSPrice] = useState();
  const [ISSMarketCap,setISSMarketCap] = useState();
  const [ISSMarketCapCurrent,setISSMarketCapCurrent] = useState();
  const [ISSSupply,setISSSupply] = useState();
  const [ISSSupplyCurrent,setISSSupplyCurrent] = useState();
  const [liveAssets,setLiveAssets] = useState();
  const [loadingBlockchainData,setLoadingBlockchainData] = useState(false);
  const [lockDate,setLockDate] = useState();
  const [loggedIn,setLoggedIn] = useState(false);
  const [LPValue,setLPValue] = useState(true);
  const [MarketFactory_Address,setMarketFactory_Address] = useState();
  const [MarketRouter_Address,setMarketRouter_Address] = useState();
  const [MasterChef_Address,setMasterChef_Address] = useState();
  const [metamaskInstalled,setMetaMaskInstalled] = useState();
  const [messageBoxVisible,setMessageBoxVisible] = useState(false);
  const [messageBoxContent,setMessageBoxContent] = useState('');
  const [nextRewardsPayment,setNextRewardsPayment] = useState('');
  const [openRewards,setOpenRewards] = useState(0);
  const [pools,setPools] = useState();
  const [RewardsMachine_Address,setRewardsMachine_Address] = useState();
  const [showAccount,setShowAccount] = useState(false);
  const [showV2,setShowV2] = useState(false);
  const [slippage,setSlippage] = useState(50);
  const [testnet,setTestnet] = useState();
  const [totalLockedValue,setTotalLockedValue] = useState();
  const [totalValue,setTotalValue] = useState();
  const [TotalVeISSSupply,setTotalVeISSSupply] = useState();
  const [trxTime,setTrxTime] = useState(30);
  const [txhash, setTxhash] = useState("");
  const [Upgrader_Address,setUpgrader_Address] = useState();
  const [USDDecimals,setUSDCDecimals] = useState();
  const [USDC_Address,setUSDC_Address] = useState();
  const [USDCAllowance,setUSDCAllowance] = useState();
  const [USDCBalance,setUSDCBalance] = useState(0);
  const [veISSBalance,setVeISSBalance] = useState();
  const [VotingEscrow_Address,setVotingEscrow_Address] = useState();
  const [VoteMachine_Address,setVoteMachine_Address] = useState();
  const [web3_nm,setWeb3_nm] = useState();
  const [wrongNetworkMessage,setWrongNetworkMessage] = useState(false);


  //smartContracts
  const [assetFactory_nm,setAssetFactory_nm] = useState();
  const [USDC_nm,setUSDC_nm] = useState();
  const [GovernanceToken_nm,setGovernanceToken_nm] = useState();
  const [VoteMachine_nm,setVoteMachine_nm] = useState();
  const [MarketRouter_nm,setMarketRouter_nm] = useState();
  const [MarketFactory_nm,setMarketFactory_nm] = useState();
  const [RewardsMachine_nm,setRewardsMachine_nm] = useState();
  const [DAO_nm,setDAO_nm] = useState();
  const [Upgrader_nm,setUpgrader_nm] = useState();
  const [VotingEscrow_nm,setVotingEscrow_nm] = useState();
  const [MasterChef_nm,setMasterChef_nm ] = useState();
  

  

  const web3Data = useAccount({
    onConnect({ address, connector, isReconnected }) {
      setAddress(address);
      console.log("Connected.");
      console.log(connector);
      setConnector(connector);
      setLoggedIn(true)
      loginWeb3();
    },
    onDisconnect() {
      setAddress("");
      console.log("Disconnected.");
      setLoggedIn(false)
      setConnector({});
    },
  });
  
  


  useEffect(() => {
    console.log(web3Data)
    console.log("")
    loginWeb3()
      
  }, [web3Data.address]);

  useEffect(() => {
    console.log(address)
    
}, [address]);

  // Initialize web3 with Browser Wallet or fallback
  useEffect(() => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      try {
        window.ethereum.enable();
        console.log(window.ethereum)
        // Subscribe to account and chain changes
        window.ethereum.on('accountsChanged', function (accounts) {
          // Handle account change
          console.log('Accounts changed:', accounts);
          loginWeb3()
        });
        window.ethereum.on('chainChanged', function (networkId) {
          // Handle chain change
          console.log('Chain changed:', networkId);
          loginWeb3()
        });
      } catch (error) {
        console.error("User denied account access");
      }
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      console.log('Non-Ethereum browser detected. You should consider trying Rabby!');
    }
    
  }, []);

  const saveSlippagePreference = (slippage) =>{
    setSlippage(slippage)
  };
  const saveTrxTimePreference = (trxTime) =>{
    this.setTrxTime(trxTime)
  };  

  function changeView(_page){
    setActivePage(_page)
  }

 

  
  async function loginWeb3() {
    setLoadingBlockchainData(true)
    let chainId
    let _address
    try{
      console.log(window.ethereum)
      chainId = window.ethereum.chainId
      _address = window.ethereum.selectedAddress
      console.log(chainId)

      console.log(address)
      await setAddress(_address)
      setLoggedIn(true)
    }
    catch{
      console.log("DEBUG")
      setLoadingBlockchainData(false)
      return
    }
    let _chainName
    let _web3_nm = new Web3(new Web3.providers.HttpProvider('https://rpc.scroll.io'));
    let _AssetFactory_Address = assetFactory_Address_b
    let _DAO_Address = DAO_Address_b
    let _fullProtocolIntegration = true
    let _GovernanceToken_Address = GovernanceToken_Address_b
    let _IDT_Address = IDT_Address_b
    let _MarketFactory_Address = MarketFactory_Address_b
    let _MarketRouter_Address = MarketRouter_Address_b
    let _MasterChef_Address = MasterChef_Address_b
    let _RewardsMachine_Address = RewardsMachine_Address_b
    let _testnet = false
    let _Upgrader_Address = Upgrader_Address_b
    let _USDC_Address = USDC_Address_b
    let _VotingEscrow_Address = VotingEscrow_Address_b
    let _VoteMachine_Address = VoteMachine_Address_b

    if (chainId === '0x138d5')  {
      console.log("Bera testnet detected")
      _web3_nm = new Web3(new Web3.providers.HttpProvider('https://artio.rpc.berachain.com/'));
      _chainName = "Berachain Artio"
      _USDC_Address = USDC_Address_ba
      _GovernanceToken_Address = GovernanceToken_Address_ba
      _IDT_Address = IDT_Address_ba
      _AssetFactory_Address = assetFactory_Address_ba
      _VoteMachine_Address = VoteMachine_Address_ba
      _MarketFactory_Address = MarketFactory_Address_ba
      _MarketRouter_Address = MarketRouter_Address_ba
      _RewardsMachine_Address = RewardsMachine_Address_ba
      _DAO_Address = DAO_Address_ba
      _Upgrader_Address = Upgrader_Address_ba
      _VoteMachine_Address = VoteMachine_Address_ba
      _VotingEscrow_Address = VotingEscrow_Address_ba
      _MasterChef_Address = MasterChef_Address_ba
      _testnet = true
      _fullProtocolIntegration = true
    }
    else{
      console.log("Unknown chain detected")
      _fullProtocolIntegration = true
      setLoadingBlockchainData(false)
      setFullProtocolIntegration(false)
      try {
        // Request permission to add a new network
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [{
            chainId: '0x138d5', // Chain ID of the network you want to add
            chainName: 'Berachain Artio',
            nativeCurrency: {
              name: 'Bera',
              symbol: 'BERA',
              decimals: 18,
            },
            rpcUrls: ['https://artio.rpc.berachain.com/'], // URL of a node on your blockchain
            blockExplorerUrls: ['https://explorer.beraartio.net'], // URL of a block explorer for your blockchain
          }],
        });
      } catch (error) {
        console.error(error);
        // Handle errors
      }
      return
    }  
    setWeb3_nm(_web3_nm);
    setChainName(_chainName)
    setUSDC_Address(_USDC_Address)
    setGovernanceToken_Address(_GovernanceToken_Address)
    setAssetFactory_Address(_AssetFactory_Address)
    setVoteMachine_Address(_VoteMachine_Address)
    setMarketFactory_Address(_MarketFactory_Address)
    setMarketRouter_Address(_MarketRouter_Address)
    setRewardsMachine_Address(_RewardsMachine_Address)
    setDAO_Address(_DAO_Address)
    setUpgrader_Address(_Upgrader_Address)
    setVoteMachine_Address(_VoteMachine_Address)
    setVotingEscrow_Address(_VotingEscrow_Address)
    setMasterChef_Address(_MasterChef_Address)
    setTestnet(_testnet)
    setFullProtocolIntegration(_fullProtocolIntegration)
    
    if (_fullProtocolIntegration === false) {
      return
    }

    if (_chainName === "Scroll"){
      console.log("DEBUGGGGG")
      setShowV2(true)
    }
    else if (_chainName === "Berachain Artio"){
      console.log("Berachain Artio")
      setShowV2(true)
    }
    else {
      setShowV2(false)
      return
    }
    
    
    const _USDC_nm = new _web3_nm.eth.Contract(USDC_ABI,_USDC_Address)
    const _IDT_nm = new _web3_nm.eth.Contract(GovernanceToken_ABI,_IDT_Address)
    const _assetFactory_nm = new _web3_nm.eth.Contract(assetFactory_ABI,_AssetFactory_Address);
    const _GovernanceToken_nm = new _web3_nm.eth.Contract(GovernanceToken_ABI,_GovernanceToken_Address)
    const _VoteMachine_nm = new _web3_nm.eth.Contract(VoteMachine_ABI,_VoteMachine_Address)
    const MarketRouter_nm = new _web3_nm.eth.Contract(MarketRouter_ABI,_MarketRouter_Address)
    const _MarketFactory_nm = new _web3_nm.eth.Contract(MarketFactory_ABI,_MarketFactory_Address)
    const _RewardsMachine_nm =  new _web3_nm.eth.Contract(RewardsMachine_ABI,_RewardsMachine_Address)
    const _DAO_nm = new _web3_nm.eth.Contract(DAO_ABI,_DAO_Address)
    const _Upgrader_nm = new _web3_nm.eth.Contract(Upgrader_ABI, _Upgrader_Address)
    const _VotingEscrow_nm = new _web3_nm.eth.Contract(VotingEscrow_ABI, _VotingEscrow_Address)
    const _MasterChef_nm = new _web3_nm.eth.Contract(MasterChef_ABI, _MasterChef_Address)
    
    setUSDC_nm(_USDC_nm)
    setAssetFactory_nm(_assetFactory_nm)
    setMarketFactory_nm(_MarketFactory_nm)
    setDAO_nm(_DAO_nm)
    setUpgrader_nm(_Upgrader_nm)
    setMasterChef_nm(_MasterChef_nm)
    setRewardsMachine_nm(_RewardsMachine_nm)
    setVotingEscrow_nm(_VotingEscrow_nm)
    setVoteMachine_nm(_VoteMachine_nm)
    setGovernanceToken_nm(_GovernanceToken_nm)
    setIDT_nm(_IDT_nm)
    

    // Load USDC Balance etc
    let _USDDecimals =  await _USDC_nm.methods.decimals(_USDC_Address).call()
    _USDDecimals = parseInt(_USDDecimals)
    setUSDCDecimals(_USDDecimals)
    console.log(_USDDecimals)
    var _USDCBalanceWEI = await _USDC_nm.methods.balanceOf(_address).call()
    let _USDCBalance
    if (_USDDecimals === 6) {
      _USDCBalance = _web3_nm.utils.fromWei(_USDCBalanceWEI.toString(), 'mwei')
    }
    else {
      _USDCBalance = _web3_nm.utils.fromWei(_USDCBalanceWEI.toString(), 'ether') 
    }

    await setUSDCBalance(_USDCBalance)
    // Load the Government Token and IDT balance as well as the staked amaount
    let _IDTBalanceWei = await _IDT_nm.methods.balanceOf(_address).call()
    setIDTBalanceWei(_IDTBalanceWei)
    let _GovernanceTokenBalanceWei = await _GovernanceToken_nm.methods.balanceOf(_address).call()
    let _ISSSupplyWei = await _GovernanceToken_nm.methods.totalSupply().call()
    let _ISSSupply = parseFloat(_web3_nm.utils.fromWei(_ISSSupplyWei.toString(), 'ether'))
    setISSSupply(_ISSSupply)
    // Get the supply that is not circulating
    let _ISSBalanceMultisigWei = await _GovernanceToken_nm.methods.balanceOf('0x0Bd8a3e3E1979D2593ed1f903aA29005E4BE0e2e').call()
    let _ISSBalanceDeployerWei = await _GovernanceToken_nm.methods.balanceOf('0x7Dbc67df4d4ea21420B1BaA077028d2c1CCa7399').call()
    //let _ISSBalanceLiquiPoolWei = await _GovernanceToken_nm.methods.balanceOf('0x6e39Ff9e2681413A6Cb753077b63Bf4d1AE94406').call()
    let _ISSBalanceLockedWei = await _GovernanceToken_nm.methods.balanceOf('0x5b0ec8eaaa8d229f905f1afbc5c17ab3cdfbb461').call()
    let _ISSBalanceDAOWei = await _GovernanceToken_nm.methods.balanceOf('0x4a00BeDEcDD6742f858C115D018EbBd36292c29D').call()
    let _ISSBalanceNotCirculatingWei = _ISSBalanceDeployerWei + _ISSBalanceMultisigWei + _ISSBalanceLockedWei + _ISSBalanceDAOWei
    let _ISSSupplyCurrentWei  = _ISSSupplyWei - _ISSBalanceNotCirculatingWei
    let _ISSSupplyCurrent = parseFloat(_web3_nm.utils.fromWei(_ISSSupplyCurrentWei.toString(), 'ether'))
    setISSSupplyCurrent(_ISSSupplyCurrent)
    console.log(_ISSSupplyCurrent)

    let _veISSSupplyWei = await _VotingEscrow_nm.methods.totalSupply().call()
    let _remainingRewards = await _GovernanceToken_nm.methods.balanceOf(_RewardsMachine_Address).call()
    let _veISSShare = parseFloat(_veISSSupplyWei / (_ISSSupplyWei - _remainingRewards))
    
    let _GovernanceTokenBalance = parseFloat(_web3_nm.utils.fromWei(_GovernanceTokenBalanceWei.toString(), 'ether'))
    console.log(_GovernanceTokenBalance)
    setGovernanceTokenBalance(_GovernanceTokenBalance)

    let _userData = await _VotingEscrow_nm.methods.userData(_address).call()
    console.log("DEBUG")
    let _GovernanceTokenStakeBalanceWei = _userData['_lockedBalance']['amount']    
    let _GovernanceTokenStakeBalance = parseFloat(_web3_nm.utils.fromWei(_GovernanceTokenStakeBalanceWei.toString(), 'ether'))
    
    let _veISSBalanceWei = _userData['_balanceVeISS']
    let _veISSBalance = parseFloat(_web3_nm.utils.fromWei(_veISSBalanceWei.toString(), 'ether'))
    let _lockDate = _userData['_lockedBalance']['end']
    let _totalVeISSSupplyWei = await _VotingEscrow_nm.methods.totalSupply().call();
    let _totalVeISSSupply = parseFloat(_web3_nm.utils.fromWei(_totalVeISSSupplyWei.toString(), 'ether'))
    
    console.log(_veISSBalance)
    setVeISSBalance(_veISSBalance)
    setLockDate(_lockDate)
    setGovernanceTokenStakeBalance(_GovernanceTokenStakeBalance)
    setTotalVeISSSupply(_totalVeISSSupply)

    let _GovernanceTokenTotalBalance = parseFloat(_GovernanceTokenStakeBalance) + parseFloat(_GovernanceTokenBalance)
    let _GovernanceTokenTotalBalanceAllChains = _GovernanceTokenTotalBalance
    let _USDCAllowance = await _USDC_nm.methods.allowance(_address, _AssetFactory_Address).call() 
    
    const assetNumber = await _assetFactory_nm.methods.assetNumber().call()

    setUSDCAllowance(_USDCAllowance)
    setGovernanceTokenTotalBalance(_GovernanceTokenTotalBalance)
    setGovernanceTokenTotalBalanceAllChains(_GovernanceTokenTotalBalanceAllChains)

    // load the different assets
    let _assets = []
    let _assetDetails = {};
    let _expiredAssets = [];
    let _liveAssets = [];
    let newAsset;
    let _pools = [];

    

    //add the ISS Token to the pools
    let pair = await _MarketFactory_nm.methods.getPair(_GovernanceToken_Address,_USDC_Address).call()
    console.log(_GovernanceToken_Address)
    console.log(_USDC_Address)
    console.log(pair)

    console.log("DEBUG1")
    let MarketPair = await new _web3_nm.eth.Contract(MarketPair_ABI,pair)
   
    let token0 = await MarketPair.methods.token0().call();
    let reserves = await MarketPair.methods.getReserves().call();
    
    let userBalance = await MarketPair.methods.balanceOf(_address).call()
    console.log(userBalance)
    let totalSupply = await MarketPair.methods.totalSupply().call();
    console.log(totalSupply)
    
    let USDReserves;
    let tokenReserves;
    let _ISSPrice;
    let ISSMarketCap;
    let reservesUSDT
    let reservesToken

    let totalLockedValue = 0;

    // Get the ISS pool data
    if (token0 === _USDC_Address) {
      USDReserves = parseInt(reserves[0]) 
      reservesToken = parseInt(reserves[1])
      _ISSPrice = USDReserves * 10**(18-_USDDecimals)/reservesToken
    }
    else{
      USDReserves = parseInt(reserves[1]) 
      reservesToken = parseInt(reserves[0])
      _ISSPrice = USDReserves * 10**(18-_USDDecimals)/reservesToken
    }
    if (isNaN(_ISSPrice)){
      _ISSPrice = 0.01
    }
    let _ISSMarketCap = _ISSPrice * _ISSSupply
    let _ISSMarketCapCurrent = _ISSPrice * _ISSSupplyCurrent
    setISSPrice(_ISSPrice)
    setISSMarketCap(_ISSMarketCap)
    setISSMarketCapCurrent(_ISSMarketCapCurrent)
    
    // Get the farm data
    let totalAllocPoint = await _MasterChef_nm.methods.totalAllocPoint().call()
    totalAllocPoint = parseInt(totalAllocPoint)
    let iSSPerSecWei = await _MasterChef_nm.methods.iSSPerSec().call()
    let iSSPerSec = parseInt(iSSPerSecWei) / 1e18
    
    let iSSPerYear = iSSPerSec * 31536000
    let annualRewardsinUSD = iSSPerYear * _ISSPrice
    let annualRewardsinUSDPerAllocPoint = annualRewardsinUSD / totalAllocPoint
    let numberOfFarms = await _MasterChef_nm.methods.poolLength().call()
    numberOfFarms = parseInt(numberOfFarms)
    

    totalLockedValue = totalLockedValue + USDReserves * 2 /10 ** (_USDDecimals) ;
    
    let currentISSSupply = await _RewardsMachine_nm.methods.getCurrentSupply().call()
    let numberOfPools = await _RewardsMachine_nm.methods.numberOfPools().call()
    
    //let individualWeeklyPoolRewards = Number(_remainingRewards)*0.3*(1-Number(_veISSShare))/(parseInt(numberOfPools)+4)
    let individualWeeklyPoolRewards = annualRewardsinUSDPerAllocPoint * 5
    console.log(individualWeeklyPoolRewards)
    let APRMax = ((individualWeeklyPoolRewards) / (USDReserves * 2 / (10**(_USDDecimals))))
    let baseAPR = APRMax * 0.4
    let poolShare = parseFloat(userBalance*BigInt(10**18)) / parseFloat(totalSupply*BigInt(10**18))
    let veISSShareUser = parseFloat(_veISSBalance*(10**18)) / parseFloat(_totalVeISSSupply*(10**18))
    let userBoost = 1
    if (veISSShareUser > 0){
      userBoost = Math.min(2.5,1 + (veISSShareUser / poolShare))
    }
    console.log(userBoost)
    let userAPR = baseAPR * userBoost

    _pools.push(["ISS",pair,USDReserves*2*(10**(18-_USDDecimals)),"Issuaa Protocol Token",parseInt(userBalance),parseInt(totalSupply),APRMax,USDReserves,reservesToken,"n.a.",_ISSPrice,userAPR]);
    
    console.log(_pools)

    for (var i = 0, size = assetNumber; i < size ; i++) {
      newAsset = await _assetFactory_nm.methods.assets(i).call();
      _assets.push(newAsset);
      _assetDetails[newAsset]  = await _assetFactory_nm.methods.getAsset(newAsset).call();
      let tokenAddress = _assetDetails[newAsset]['Token1'];
      let tokenContract = new _web3_nm.eth.Contract(ERC20_ABI,tokenAddress)
      let tokenBalanceWEI = await tokenContract.methods.balanceOf(_address).call()
      let tokenBalance = _web3_nm.utils.fromWei(tokenBalanceWEI.toString(), 'ether')
      _assetDetails[newAsset]['tokenBalance1'] = tokenBalance
      _assetDetails[newAsset]['tokenAllowance1'] = await tokenContract.methods.allowance(_address, _AssetFactory_Address).call()
      try{
        let pair = await _MarketFactory_nm.methods.getPair(tokenAddress,_USDC_Address).call()
        let MarketPair = await new _web3_nm.eth.Contract(MarketPair_ABI,pair)
        let reserves = await MarketPair.methods.getReserves().call();
        let userBalance = await MarketPair.methods.balanceOf(_address).call()
        let totalSupply = await MarketPair.methods.totalSupply().call();
        let token0 = await MarketPair.methods.token0().call();
        let USDReserves;
        console.log(totalLockedValue)
        if (token0 === _USDC_Address) {
          USDReserves = parseInt(reserves[0])
          tokenReserves = parseInt(reserves[1])
          totalLockedValue += USDReserves*2/10 ** (_USDDecimals)
        }
        else{
          USDReserves = parseInt(reserves[1])
          tokenReserves = parseInt(reserves[0])
          totalLockedValue += USDReserves*2/10 ** (_USDDecimals)
          
        }
        console.log(totalLockedValue)
        let individualWeeklyPoolRewards = annualRewardsinUSDPerAllocPoint * 1
        let APRMax = ((individualWeeklyPoolRewards) / (USDReserves * 2 / (10**(_USDDecimals))))
        _pools.push([newAsset,pair,USDReserves*2*(10**(18-_USDDecimals)),_assetDetails[newAsset][2],parseInt(userBalance),parseInt(totalSupply),APRMax,USDReserves,tokenReserves,_assetDetails[newAsset]['upperLimit'],(USDReserves/tokenReserves)*(10**(18-_USDDecimals))]);
        _assetDetails[newAsset]['poolBalanceLong'] = userBalance
        _assetDetails[newAsset]['price'] = USDReserves/tokenReserves;
        _assetDetails[newAsset]['priceLong'] = (USDReserves/tokenReserves)*(10**(18-_USDDecimals))
      }
      catch{console.log("Pool not found")}
          

      tokenAddress = _assetDetails[newAsset]['Token2'];
      let tokenContract1 = new _web3_nm.eth.Contract(ERC20_ABI,tokenAddress)
      let tokenBalanceWEI1 = await tokenContract1.methods.balanceOf(_address).call()
      let tokenBalance1 = _web3_nm.utils.fromWei(tokenBalanceWEI1.toString(), 'ether')
      
      _assetDetails[newAsset]['tokenBalance2'] = tokenBalance1
      _assetDetails[newAsset]['tokenAllowance2'] = await tokenContract.methods.allowance(_address, _AssetFactory_Address).call()
      try{
        pair = await _MarketFactory_nm.methods.getPair(tokenAddress,_USDC_Address).call()
        MarketPair = await new _web3_nm.eth.Contract(MarketPair_ABI,pair)
        reserves = await MarketPair.methods.getReserves().call();
        userBalance = await MarketPair.methods.balanceOf(_address).call()
        totalSupply = await MarketPair.methods.totalSupply().call();
        token0 = await MarketPair.methods.token0().call();
        
        if (token0 === _USDC_Address) {
          USDReserves = parseInt(reserves[0])
          tokenReserves = parseInt(reserves[1])
          totalLockedValue = totalLockedValue + USDReserves * 2/10 ** (_USDDecimals)
          }
        else{
          USDReserves = parseInt(reserves[1])
          tokenReserves = parseInt(reserves[0])
          totalLockedValue = totalLockedValue + USDReserves * 2/10 ** (_USDDecimals)
        }
        console.log(individualWeeklyPoolRewards)
        console.log(_ISSPrice)
        console.log(USDReserves)

        individualWeeklyPoolRewards = annualRewardsinUSDPerAllocPoint * 1
        APRMax = ((individualWeeklyPoolRewards) / (USDReserves * 2 / (10**(_USDDecimals))))
        
        _pools.push(["i"+newAsset,pair, parseInt(USDReserves)*2*(10**(18-_USDDecimals)),"short "+_assetDetails[newAsset][2],parseInt(userBalance),parseInt(totalSupply),APRMax,parseInt(USDReserves),tokenReserves,_assetDetails[newAsset]['upperLimit'],(parseInt(USDReserves)/parseInt(tokenReserves))*(10**(18-_USDDecimals))]);
        _assetDetails[newAsset]['poolBalanceShort'] = userBalance
        _assetDetails[newAsset]['priceShort'] = (parseInt(USDReserves)/tokenReserves)*(10**(18-_USDDecimals))
        
      }
      catch{console.log("Pool not found")}
      
      
      let freezeVotes = await _VoteMachine_nm.methods.getFreezeVotes(newAsset).call()
      _assetDetails[newAsset]['voteOpen'] = freezeVotes.open
      _assetDetails[newAsset]['yesVotes'] = freezeVotes.yesVotes
      _assetDetails[newAsset]['noVotes'] = freezeVotes.noVotes
      _assetDetails[newAsset]['votesEndingTime'] = freezeVotes.endingTime
      _assetDetails[newAsset]['votesStartingTime'] = freezeVotes.startingTime
      let hasVoted = await _VoteMachine_nm.methods.checkIfVoted(_address,newAsset).call()
      _assetDetails[newAsset]['hasVoted'] = hasVoted
      
      
      let expiryVotes = await _VoteMachine_nm.methods.getEndOfLifeVotes(newAsset).call()
      _assetDetails[newAsset]['expiryVoteOpen'] = expiryVotes.open
      _assetDetails[newAsset]['expiryVoteExists'] = expiryVotes.exists
      _assetDetails[newAsset]['expiryVoteEndingTime'] = expiryVotes.endingTime


      let hasVotedOnExpiry = await _VoteMachine_nm.methods.checkIfVotedOnExpiry(_address,newAsset).call()
      _assetDetails[newAsset]['hasVotedOnExpiry'] = hasVotedOnExpiry
      
    };
    
    setAssetDetails(_assetDetails)
    setPools(_pools)
    setTotalLockedValue(totalLockedValue)


    
    console.log("updating farms")
    // This is the converted updateFarms
    let _farms = []
    let _farmsValue = 0
    let otherPoolData = []
    
    for (var i = 0, size = numberOfFarms; i < size ; i++) {
      let poolInfo = await _MasterChef_nm.methods.poolInfo(i).call()
      let userInfo = await _MasterChef_nm.methods.userInfo(i,_address).call()
      console.log(userInfo)
      let lpToken = new _web3_nm.eth.Contract(ERC20_ABI,poolInfo['lpToken'])
      let totalAmountBN = await lpToken.methods.balanceOf(_MasterChef_Address).call()
      let totalAmount = parseInt(totalAmountBN)
      let allowanceBN = await lpToken.methods.allowance(_address, _MasterChef_Address).call()
      let allowance = parseInt(allowanceBN)
      let approved = false 
      if (allowance > 100000000000000000000000000000){
        approved = true
      }
      
      for (let pool in _pools) {
        if(_pools[pool][1] === poolInfo['lpToken']){
          otherPoolData = _pools[pool]
        }
      }
      let userFarmValue = 2* parseFloat(otherPoolData[7]) * parseFloat(userInfo['amount']) / parseFloat(otherPoolData[5]);
      if (parseFloat(totalAmount) > 0){
        _farmsValue += userFarmValue
      }
      
      
      let priceLPToken = (otherPoolData[2] / 1e18) / otherPoolData[5]
      let USDValueOfPool = priceLPToken * totalAmount
      console.log(USDValueOfPool)
      let maxAPR = (annualRewardsinUSDPerAllocPoint * parseInt(poolInfo['allocPoint'])) / USDValueOfPool
      //let maxAPR = (parseInt(annualRewardsinUSD) * parseInt(poolInfo['allocPoint']))/(parseInt(totalAmount) * priceLPToken * parseInt(totalAllocPoint) / 1e18)
      console.log(maxAPR)
      let lastRewardTimestamp = parseInt(poolInfo['lastRewardTimestamp'])
      let now = parseInt(new Date().getTime()/1000);
      let multiplier = now - lastRewardTimestamp;
      let ISSRewardsWEI = parseFloat(multiplier * parseInt(iSSPerSecWei) * parseInt(poolInfo['allocPoint']) / parseInt(totalAllocPoint));
      let accISSPerShareSinceLastRewards = parseFloat(ISSRewardsWEI*1e12/totalAmount)
      let accISSPerShare = parseFloat(parseInt(poolInfo['accISSPerShare']) + accISSPerShareSinceLastRewards)

      let claimableBalanceBeforeBoost = parseFloat(((parseFloat(userInfo['amount']) * accISSPerShare / 1e12) - parseInt(userInfo['rewardDebt']))*0.4);
      let claimableBalance = claimableBalanceBeforeBoost * parseFloat(userInfo['boostFactor']) / 1e12
      let userValue = parseFloat(userInfo['amount']) * parseFloat(otherPoolData[2]) /parseFloat(otherPoolData[5])
      let farmData = {'lpToken': poolInfo['lpToken'],'accISSPerShare':parseInt(poolInfo['accISSPerShare']),'allocPoint':parseInt(poolInfo['allocPoint']),'userAmount': parseInt(userInfo['amount']),
                      'totalAmount':totalAmount,'boostFactor':parseInt(userInfo['boostFactor']),'rewardDebt':parseInt(userInfo['rewardDebt']),'symbol':otherPoolData[0],
                      'name': otherPoolData[3], 'availableBalance': otherPoolData[4],'totalSupply': otherPoolData[5],'USDCReserves':otherPoolData[7],'tokenReserves':otherPoolData[8],
                      'tvl':otherPoolData[2],'upperLimit': parseInt(otherPoolData[9]), 'priceAsset': otherPoolData[10],'priceLPToken': priceLPToken, 'maxAPR': maxAPR,
                      'claimableBalance' : claimableBalance,'approved':approved,'pid': i,'userValue':userValue
                    }

      _farms.push(farmData)
    }


    setFarms(_farms)
    console.log(_farms)
    setFarmsValue(_farmsValue)
    

    
    // Replacement of updatePortfolioValue function
    let updatedAssetValue = (_GovernanceTokenTotalBalance * _ISSPrice) + parseFloat(_USDCBalance)
    let updatedFarmsValue = parseFloat(_farmsValue)/1e6
    let updatedLPValue = 0
    
    for (let i = 0, size = _pools.length; i < size ; i++) {
      console.log(_pools)
      console.log(_pools[i][4])
      console.log(_pools[i][5])
      console.log(_pools[i][2])
      updatedLPValue = updatedLPValue + ((parseFloat(_pools[i][4])/parseFloat(_pools[i][5]))*parseFloat(_pools[i][2])/1e18)
      }
    setLPValue(updatedLPValue)
    console.log(updatedLPValue)
    
    for (let s in assetDetails) {
      updatedAssetValue += assetDetails[s]['tokenBalance1'] * assetDetails[s]['priceLong']
      updatedAssetValue += assetDetails[s]['tokenBalance2'] * assetDetails[s]['priceShort']

    }
    let updatedTotalValue = updatedAssetValue + updatedLPValue + updatedFarmsValue

    setAssetValue(updatedAssetValue)
    setTotalValue(updatedTotalValue)
    
    for (let i = 0, size = assetNumber; i < size ; i++) {
      let asset = _assets[i];
      if (_assetDetails[asset].frozen !== true && _assetDetails[asset].expiryTime > Date.now()/1000) {
        _liveAssets.push(asset);
      }
      else{
        _expiredAssets.push(asset);
      }
    setLiveAssets(_liveAssets)
    setExpiredAssets(_expiredAssets)
    
    }
    
    setAssets(_assets)
    setLoadingBlockchainData(false)
    setblockchainDataLoaded(true)
    console.log("Blockchain data is loaded")
  
  }

  async function transactWithContract(_contractName, _functionName, _args) {
    console.log("Starting transaction")
    console.log(_contractName)
    console.log(_functionName)
    console.log(_args)
    let contractAddress = ''
    let contractInterface = []
    if (_contractName === 'assetFactory'){contractAddress = assetFactory_Address, contractInterface = assetFactory_ABI}
    else if (_contractName === 'marketRouter'){contractAddress = MarketRouter_Address, contractInterface = MarketRouter_ABI}
    else if (_contractName === 'voteMachine'){contractAddress = VoteMachine_Address, contractInterface = VoteMachine_ABI}
    else if (_contractName === 'upgrader'){contractAddress = Upgrader_Address, contractInterface = Upgrader_ABI}
    else if (_contractName === 'dAO'){contractAddress = DAO_Address, contractInterface = DAO_ABI}
    else if (_contractName === 'masterChef'){contractAddress = MasterChef_Address, contractInterface = MasterChef_ABI}
    else if (_contractName === 'votingEscrow'){contractAddress = VotingEscrow_Address, contractInterface = VotingEscrow_ABI}
    else if (_contractName === 'mockUSDC'){contractAddress = USDC_Address, contractInterface = USDC_ABI}
    
    else {
      console.log("Contract not found")
      return false}
    try {
      console.log(contractAddress)
      const { request }  = await prepareWriteContract({
        address: contractAddress,
        abi: contractInterface,
        functionName: _functionName,
        args: _args
      })
      const { hash } = await writeContract(request)
      const resp = await waitForTransaction({ hash });
      console.log(resp)
      if (resp['status'] == "success"){
        closeMessageBox()
        return true
      }
      else{
        let message = "Transaction failed"
        setMessageBoxContent(message)
        await sleep(10000)
        closeMessageBox()
        return false
      }
    } catch (e) {
      console.log( `Transaction error : ${e.message}`);
      let message = `Transaction error : ${e.message}`;
      setMessageBoxContent(message)
      await sleep(10000)
      closeMessageBox()
      return false
      
    }
  }
  async function approveToken(_contractAddress,_approvalAddress) {
    try {
      const { request }  = await prepareWriteContract({
        address: _contractAddress,
        abi: ERC20_ABI,
        functionName: 'approve',
        args: [_approvalAddress,web3_nm.utils.toBigInt(2**255)]
      })
      const { hash } = await writeContract(request)
      const resp = await waitForTransaction({ hash });
      console.log(resp)
      if (resp['status'] == "success"){
        closeMessageBox()
      }
      else{
        let message = "Transaction failed"
        setMessageBoxContent(message)
        await sleep(10000)
        closeMessageBox()
      }
      return(resp)
    } catch (e) {
      console.log( `Transaction error : ${e.message}`);
      let message = `Transaction error : ${e.message}`;
      setMessageBoxContent(message)
      await sleep(10000)
      closeMessageBox()
      
    }
  }

  const updateGovernanceTokenBalanceWithoutVesting = async() => {
    // Load the different contracts
    const web3_nm = await web3_nm;
    const GovernanceToken_nm = new web3_nm.eth.Contract(GovernanceToken_ABI,GovernanceToken_Address)
          
    // Load the Government Token balance
    var GovernanceTokenBalanceWei = await GovernanceToken_nm.methods.balanceOf(address).call()
    var GovernanceTokenBalance = parseFloat(web3_nm.utils.fromWei(GovernanceTokenBalanceWei.toString(), 'ether'))
    this.setState({GovernanceTokenBalance})
    if (fullProtocolIntegration === false) {
      this.setState({GovernanceTokenTotalBalance: GovernanceTokenBalance})
    }
    console.log("Blockchain data is loaded")
  }

  async function loadLimitedBlockchainData() {
    // Load the Government Token balance as well as the staked amaount

    let _IDTBalanceWei = await IDT_nm.methods.balanceOf(address).call()
    setIDTBalanceWei(_IDTBalanceWei)
    let _GovernanceTokenBalanceWei = await GovernanceToken_nm.methods.balanceOf(address).call()
    let _ISSSupplyWei = await GovernanceToken_nm.methods.totalSupply().call()
    let _ISSSupply = parseFloat(web3_nm.utils.fromWei(_ISSSupplyWei.toString(), 'ether'))
    setISSSupply(_ISSSupply)
    let _veISSSupplyWei = await VotingEscrow_nm.methods.totalSupply().call()
    let _remainingRewards = await GovernanceToken_nm.methods.balanceOf(RewardsMachine_Address).call()
    let _veISSShare = parseFloat(_veISSSupplyWei / (_ISSSupplyWei - _remainingRewards))

    let _GovernanceTokenBalance = parseFloat(web3_nm.utils.fromWei(_GovernanceTokenBalanceWei.toString(), 'ether'))
    console.log(_GovernanceTokenBalance)
    setGovernanceTokenBalance(_GovernanceTokenBalance)

    let _userData = await VotingEscrow_nm.methods.userData(address).call()
    let _GovernanceTokenStakeBalanceWei = _userData['_lockedBalance']['amount']    
    let _GovernanceTokenStakeBalance = parseFloat(web3_nm.utils.fromWei(_GovernanceTokenStakeBalanceWei.toString(), 'ether'))
    
    let _veISSBalanceWei = _userData['_balanceVeISS']
    let _veISSBalance = parseFloat(web3_nm.utils.fromWei(_veISSBalanceWei.toString(), 'ether'))
    let _lockDate = _userData['_lockedBalance']['end']
    let _totalVeISSSupplyWei = await VotingEscrow_nm.methods.totalSupply().call();
    let _totalVeISSSupply = parseFloat(web3_nm.utils.fromWei(_totalVeISSSupplyWei.toString(), 'ether'))
    
    setVeISSBalance(_veISSBalance)
    setLockDate(_lockDate)
    setGovernanceTokenStakeBalance(_GovernanceTokenStakeBalance)
    setTotalVeISSSupply(_totalVeISSSupply)

    let _GovernanceTokenTotalBalance = parseFloat(_GovernanceTokenStakeBalance) + parseFloat(_GovernanceTokenBalance)
    let _GovernanceTokenTotalBalanceAllChains = _GovernanceTokenTotalBalance
    let _USDCAllowance = await USDC_nm.methods.allowance(address, assetFactory_Address).call() 
    
    const assetNumber = await assetFactory_nm.methods.assetNumber().call()
    

    setUSDCAllowance(_USDCAllowance)
    setGovernanceTokenTotalBalance(_GovernanceTokenTotalBalance)
    setGovernanceTokenTotalBalanceAllChains(_GovernanceTokenTotalBalanceAllChains)

     ////////////// 
    
    console.log("Blockchain data is loaded")
  }


  async function loadBlockchainData() {
  /*
  */
  }

  

  function openMessageBox(message){
    setMessageBoxVisible(true)
    setMessageBoxContent(message)
  }

  function closeMessageBox(){
    setMessageBoxVisible(false)
  }

  const checkUSDAllowanceAssetFactory = async () => {
       // check if the the allowance has been set for the AssetFactory contract to spend USDT
      let allowance = await USDC_nm.methods.allowance(address, assetFactory_Address).call()
      setUSDCAllowance(allowance)

    };
  

  const loadUSDBalance = async() =>{
    //Load the USDC balance
    await sleep(2000);
    console.log(USDCBalance)
    console.log(USDDecimals)  
    var balanceWEI = await USDC_nm.methods.balanceOf(address).call()
    console.log(balanceWEI)
    let balance
    if (parseInt(USDDecimals) === 6) {
      balance = parseInt(balanceWEI)/1e6
    }
    else {
      balance = parseInt(balanceWEI)/1e18
    }
    console.log(balance)
    await setUSDCBalance(balance)
    
  };

  const updateExpiryVotes = async () => {
    let _assetDetails = assetDetails;
    for (let asset in assetDetails) {
      let expiryVotes = await VoteMachine_nm.methods.getEndOfLifeVotes(asset).call()
      _assetDetails[asset]['expiryVoteOpen'] = expiryVotes.open
      _assetDetails[asset]['expiryVoteExists'] = expiryVotes.exists
      _assetDetails[asset]['expiryVoteEndingTime'] = expiryVotes.endingTime
      let hasVotedOnExpiry = await VoteMachine_nm.methods.checkIfVotedOnExpiry(address,asset).call()
      _assetDetails[asset]['hasVotedOnExpiry'] = hasVotedOnExpiry
    }
    setAssetDetails(_assetDetails)
  }

  const updateFreezeVotes = async () => {
    let _assetDetails = assetDetails;
    for (let asset in _assetDetails) {
      let freezeVotes = await VoteMachine_nm.methods.getFreezeVotes(asset).call()
      _assetDetails[asset]['voteOpen'] = freezeVotes.open
      _assetDetails[asset]['yesVotes'] = freezeVotes.yesVotes
      _assetDetails[asset]['noVotes'] = freezeVotes.noVotes
      _assetDetails[asset]['votesEndingTime'] = freezeVotes.endingTime
      _assetDetails[asset]['votesStartingTime'] = freezeVotes.startingTime
      let hasVoted = await VoteMachine_nm.methods.checkIfVoted(address,asset).call()
      _assetDetails[asset]['hasVoted'] = hasVoted
    }
    setAssetDetails(_assetDetails)
  }

  const updateAssetBalance = async(symbol) =>{
    console.log("Loading new Asset Balance");
    await sleep(3000);
    let _assetDetails = assetDetails;
    let details  = await assetFactory_nm.methods.getAsset(symbol).call();
    
    let tokenAddress = details['Token1'];
    let tokenContract = new web3_nm.eth.Contract(ERC20_ABI,tokenAddress)
    let tokenBalanceWEI = await tokenContract.methods.balanceOf(address).call()
    console.log("Balance of ",symbol," : ",tokenBalanceWEI)
    let tokenBalance = await web3_nm.utils.fromWei(tokenBalanceWEI.toString(), 'ether')
    _assetDetails[symbol]['tokenBalance1'] = parseFloat(tokenBalance)

    tokenAddress = details['Token2'];
    tokenContract = new web3_nm.eth.Contract(ERC20_ABI,tokenAddress)
    tokenBalanceWEI = await tokenContract.methods.balanceOf(address).call()
    console.log("Balance of short",symbol," : ",tokenBalanceWEI)
    tokenBalance = await web3_nm.utils.fromWei(tokenBalanceWEI.toString(), 'ether')
    _assetDetails[symbol]['tokenBalance2'] = parseFloat(tokenBalance)

    // Update the LP token details
    let pair = await MarketFactory_nm.methods.getPair(details['Token1'],USDC_Address).call()
    let MarketPair = await new web3_nm.eth.Contract(MarketPair_ABI,pair)
    let userBalance = await MarketPair.methods.balanceOf(address).call()
    _assetDetails[symbol]['poolBalanceLong'] = parseFloat(userBalance)

    pair = await MarketFactory_nm.methods.getPair(details['Token2'],USDC_Address).call()
    MarketPair = await new web3_nm.eth.Contract(MarketPair_ABI,pair)
    userBalance = await MarketPair.methods.balanceOf(address).call()
    _assetDetails[symbol]['poolBalanceShort'] = parseFloat(userBalance)

    console.log(_assetDetails)
    setAssetDetails(_assetDetails)
  }

  const updateISSData = async() =>{
    // GET THE USER BALANCE
    let _GovernanceTokenBalanceWei = await GovernanceToken_nm.methods.balanceOf(address).call()
    let _GovernanceTokenBalance = parseFloat(web3_nm.utils.fromWei(_GovernanceTokenBalanceWei.toString(), 'ether'))
    let _GovernanceTokenBalanceAllChains = _GovernanceTokenBalance
    
    // GET THE USERS veISS BALANCES AND LOCK DATES
    var userData = await VotingEscrow_nm.methods.userData(address).call()
    let _GovernanceTokenStakeBalanceWei = userData['_lockedBalance']['amount']
    let _GovernanceTokenStakeBalance = parseInt(_GovernanceTokenStakeBalanceWei)/(10**18)
    let _veISSBalanceWei = userData['balanceVeISS']/(10**18)
    var _veISSBalance = parseInt(_veISSBalanceWei)
    let _lockDate = userData['_lockedBalance']['end']
    var _GovernanceTokenTotalBalance = parseFloat(_GovernanceTokenStakeBalance) + parseFloat(_GovernanceTokenBalanceAllChains)
    
    let pair = await MarketFactory_nm.methods.getPair(GovernanceToken_Address,USDC_Address).call()
    let MarketPair = await new web3_nm.eth.Contract(MarketPair_ABI,pair)
    let token0 = await MarketPair.methods.token0().call();
    let reserves = await MarketPair.methods.getReserves().call();
    let _ISSPrice;
    
    if (token0 === USDC_Address) {
        _ISSPrice = (parseInt(reserves[0])/parseInt(reserves[1]))*(10**(18-USDDecimals));
      }
    else{
        _ISSPrice = (parseInt(reserves[1])/parseInt(reserves[0]))*(10**(18-USDDecimals));
    }
    setGovernanceTokenStakeBalance(_GovernanceTokenStakeBalance)
    setGovernanceTokenTotalBalance(_GovernanceTokenTotalBalance)
    setGovernanceTokenBalance(_GovernanceTokenBalance)
    setGovernanceTokenTotalBalanceAllChains(_GovernanceTokenTotalBalance)
    setVeISSBalance(_veISSBalance)
    setLockDate(_lockDate)
    setISSPrice(_ISSPrice)
    return true
  }

  const updateAssetBalanceWithAddress = async(_address) =>{
    console.log("Loading new Asset Balance");
    if (_address === GovernanceToken_Address) {
      updateISSData()
      return
    }
    let symbol = ''
    let _assetDetails = assetDetails;
    for (let s in assetDetails) {
      if (_assetDetails[s][0] === _address || _assetDetails[s][1] === _address){
        symbol = s
      }
    }
console.log(symbol)
    let details  = await assetFactory_nm.methods.getAsset(symbol).call();
    
    let tokenAddress = details['Token1'];
    let tokenContract = new web3_nm.eth.Contract(ERC20_ABI,tokenAddress)
    let tokenBalanceWEI = await tokenContract.methods.balanceOf(address).call()
    console.log("Balance of ",symbol," : ",tokenBalanceWEI)
    let tokenBalance = web3_nm.utils.fromWei(tokenBalanceWEI.toString(), 'ether')
    assetDetails[symbol]['tokenBalance1'] = tokenBalance

    tokenAddress = details['Token2'];
    tokenContract = new web3_nm.eth.Contract(ERC20_ABI,tokenAddress)
    tokenBalanceWEI = await tokenContract.methods.balanceOf(address).call()
    console.log("Balance of short",symbol," : ",tokenBalanceWEI)
    tokenBalance = web3_nm.utils.fromWei(tokenBalanceWEI.toString(), 'ether')
    assetDetails[symbol]['tokenBalance2'] = tokenBalance

    // Update the LP token details
    let pair = await MarketFactory_nm.methods.getPair(details['Token1'],USDC_Address).call()
    let MarketPair = await new web3_nm.eth.Contract(MarketPair_ABI,pair)
    let userBalance = await MarketPair.methods.balanceOf(address).call()
    assetDetails[symbol]['poolBalanceLong'] = userBalance

    pair = await MarketFactory_nm.methods.getPair(details['Token2'],USDC_Address).call()
    MarketPair = await new web3_nm.eth.Contract(MarketPair_ABI,pair)
    userBalance = await MarketPair.methods.balanceOf(address).call()
    assetDetails[symbol]['poolBalanceShort'] = userBalance

    setAssetDetails(_assetDetails)
  }
  
  const updateLPPair = async(_tokenAddress) =>{
    let pair = await MarketFactory_nm.methods.getPair(_tokenAddress,USDC_Address).call()
    let MarketPair = await new web3_nm.eth.Contract(MarketPair_ABI,pair)
    let reserves = await MarketPair.methods.getReserves().call();
    let userBalance = await MarketPair.methods.balanceOf(address).call()
    let totalSupply = await MarketPair.methods.totalSupply().call();
    let token0 = await MarketPair.methods.token0().call();
    let USDReserves;
    let tokenReserves;
    if (token0 === USDC_Address) {
          USDReserves = parseInt(reserves[0])
          tokenReserves = parseInt(reserves[1])
          
    }
    else{
          USDReserves = parseInt(reserves[1])
          tokenReserves = parseInt(reserves[0])
          
    }
    let _pools = pools;
    console.log(_pools)
    let selector
    for (var i = 0, size = _pools.length; i < size ; i++) {
      if (_pools[i][1] === pair){
        selector = i
      }
    }
    let poolData = _pools[selector]
    poolData[2] = USDReserves*2*(10**(18-USDDecimals));
    poolData[4] = parseInt(userBalance)
    poolData[5] = parseInt(totalSupply)
    poolData[7] = USDReserves
    poolData[8] = tokenReserves

    _pools[selector] = poolData
    setPools(_pools)
    
  }
  const updateLPPairWithAddress = async(pair) =>{
    let MarketPair = await new web3_nm.eth.Contract(MarketPair_ABI,pair)
    let reserves = await MarketPair.methods.getReserves().call();
    let userBalance = await MarketPair.methods.balanceOf(address).call()
    let totalSupply = await MarketPair.methods.totalSupply().call();
    let token0 = await MarketPair.methods.token0().call();
    let USDReserves;
    let tokenReserves;
    if (token0 === USDC_Address) {
          USDReserves = parseInt(reserves[0])
          tokenReserves = parseInt(reserves[1])
          
    }
    else{
          USDReserves = parseInt(reserves[1])
          tokenReserves = parseInt(reserves[0])
          
    }
    let pools = pools;
    let selector
    for (var i = 0, size = pools.length; i < size ; i++) {
      if (pools[i][1] === pair){
        selector = i
      }
    }
    let poolData = pools[selector]
    poolData[2] = USDReserves*2*(10**(18-USDDecimals));
    poolData[4] = userBalance
    poolData[5] = totalSupply
    poolData[7] = USDReserves
    poolData[8] = tokenReserves
    pools[selector] = poolData

    this.setState({pools})
    
  }

  const checkRewards = async() => {
    
    let _openRewards = await RewardsMachine_nm.methods.getRewards(address).call();
    let _nextRewardsPayment = await RewardsMachine_nm.methods.nextRewardsPayment().call();
    setOpenRewards(_openRewards)
    setNextRewardsPayment(_nextRewardsPayment)
    var _GovernanceTokenBalanceWei = await GovernanceToken_nm.methods.balanceOf(address).call()
    var _GovernanceTokenBalance = parseFloat(web3_nm.utils.fromWei(_GovernanceTokenBalanceWei.toString(), 'ether'))
    setGovernanceTokenBalance(_GovernanceTokenBalance)
    var userData = await VotingEscrow_nm.methods.userData(address).call()
    let _GovernanceTokenStakeBalanceWei = userData['_lockedBalance']['amount']
    var _GovernanceTokenStakeBalance = parseFloat(web3_nm.utils.fromWei(_GovernanceTokenStakeBalanceWei.toString(), 'ether'))
    setGovernanceTokenStakeBalance(_GovernanceTokenStakeBalance)
    let _veISSBalanceWei = userData['_balanceVeISS']
    var _veISSBalance = parseFloat(web3_nm.utils.fromWei(_veISSBalanceWei.toString(), 'ether'))
    setVeISSBalance(_veISSBalance)
    let _lockDate = userData['_lockedBalance']['end']
    setLockDate(_lockDate)
    
    var _GovernanceTokenTotalBalance = parseFloat(_GovernanceTokenStakeBalance) + parseFloat(_GovernanceTokenBalance)
    setGovernanceTokenTotalBalance(_GovernanceTokenTotalBalance)
    
  }

  const updatePortfolioValue = async() =>{
    let updatedAssetValue = 0
    let updatedLPValue = 0
    let updatedFarmsValue = 0
    let updatedTotalValue = 0
    try {
      updatedAssetValue += (GovernanceTokenTotalBalance * ISSPrice) + parseFloat(USDCBalance)
      }
    catch{
      updatedAssetValue += parseFloat(USDCBalance)
    }

    updatedFarmsValue += parseFloat(farmsValue)/1e6
   
    let _pools = pools;
    
    for (let i = 0, size = pools.length; i < size ; i++) {
      updatedLPValue = updatedLPValue + ((parseFloat(_pools[i][4])/parseFloat(_pools[i][5]))*parseFloat(_pools[i][2])/1e18)
      }
    let _assetDetails = assetDetails;
    
    for (let s in assetDetails) {
      updatedAssetValue += _assetDetails[s]['tokenBalance1'] * _assetDetails[s]['priceLong']
      updatedAssetValue += _assetDetails[s]['tokenBalance2'] * _assetDetails[s]['priceShort']

    }
    updatedTotalValue = updatedAssetValue + updatedLPValue + updatedFarmsValue

    setLPValue(updatedLPValue)
    setAssetValue(updatedAssetValue)
    setTotalValue(updatedTotalValue)
    return
  }

 const updateFarms = async() => {
    sleep(4000)
    console.log("updating farms")
    let _farms = []
    let _farmsValue = 0
    let otherPoolData = []
    let _totalAllocPoint = await MasterChef_nm.methods.totalAllocPoint().call()
    let totalAllocPoint = parseInt(_totalAllocPoint)
    let iSSPerSecWei = await MasterChef_nm.methods.iSSPerSec().call()
    let iSSPerSecBNWei = web3_nm.utils.toBigInt(iSSPerSecWei)
    let iSSPerSec = parseInt(iSSPerSecWei) / 1e18
    //console.log(iSSPerSec)

    let iSSPerYear = iSSPerSec * 31536000
    console.log(iSSPerYear)
    let annualRewardsinUSD = parseFloat(iSSPerYear * ISSPrice)
    let numberOfFarms = await MasterChef_nm.methods.poolLength().call()
    console.log(numberOfFarms)
    for (var i = 0, size = numberOfFarms; i < size ; i++) {
      let poolInfo = await MasterChef_nm.methods.poolInfo(i).call()
      let userInfo = await MasterChef_nm.methods.userInfo(i,address).call()
      console.log(userInfo)
      let lpToken = new web3_nm.eth.Contract(ERC20_ABI,poolInfo['lpToken'])
      let totalAmount = parseInt(await lpToken.methods.balanceOf(MasterChef_Address).call())
      let allowance = await lpToken.methods.allowance(address, MasterChef_Address).call()

      let approved = false 
      if (parseInt(allowance) > parseInt(web3_nm.utils.toBigInt(100000000000000000000000000000))){
        approved = true
      }
      for (let pool in pools) {
        //console.log(pools[pool][1]," - ",poolInfo['lpToken'])
        if(pools[pool][1] === poolInfo['lpToken']){
          otherPoolData = pools[pool]
        }
      }
      let userFarmValue = 2* parseFloat(otherPoolData[7]) * parseFloat(userInfo['amount']) / parseFloat(otherPoolData[5]);
      //console.log(userFarmValue)
      if (parseFloat(totalAmount) > 0){
        _farmsValue += userFarmValue
      }
      
      let priceLPToken = parseFloat(otherPoolData[2]/(otherPoolData[5]))
      let maxAPR = (annualRewardsinUSD * parseInt(poolInfo['allocPoint']))/(totalAmount * priceLPToken * parseInt(totalAllocPoint) / 1e18)
      console.log(maxAPR)
      let lastRewardTimestamp = poolInfo['lastRewardTimestamp']
      let now = parseInt(new Date().getTime()/1000);
      let multiplier = now - parseInt(lastRewardTimestamp);
      let ISSRewardsWEI = parseFloat(multiplier * parseInt(iSSPerSecWei) * parseInt(poolInfo['allocPoint']) / totalAllocPoint);
      let accISSPerShareSinceLastRewards = parseFloat(ISSRewardsWEI*1e12/totalAmount)
      let accISSPerShare = (parseInt(poolInfo['accISSPerShare']) + accISSPerShareSinceLastRewards)


      let claimableBalanceBeforeBoost = parseFloat(((parseInt(userInfo['amount']) * accISSPerShare / 1e12) - parseInt(userInfo['rewardDebt']))*0.4);
      let claimableBalance = claimableBalanceBeforeBoost * parseFloat(userInfo['boostFactor']) / 1e12
      //console.log(claimableBalance)
      //console.log(otherPoolData)
      let userValue = parseInt(userInfo['amount']) * parseInt(otherPoolData[2]) /parseInt(otherPoolData[5])
      let farmData = {'lpToken': poolInfo['lpToken'],'accISSPerShare':parseInt(poolInfo['accISSPerShare']),'allocPoint':parseInt(poolInfo['allocPoint']),'userAmount': parseInt(userInfo['amount']),
                      'totalAmount':totalAmount,'boostFactor':parseInt(userInfo['boostFactor']),'rewardDebt':parseInt(userInfo['rewardDebt']),'symbol':otherPoolData[0],
                      'name': otherPoolData[3], 'availableBalance': otherPoolData[4],'totalSupply': otherPoolData[5],'USDCReserves':otherPoolData[7],'tokenReserves':otherPoolData[8],
                      'tvl':otherPoolData[2],'upperLimit': parseInt(otherPoolData[9]), 'priceAsset': otherPoolData[10],'priceLPToken': priceLPToken, 'maxAPR': maxAPR,
                      'claimableBalance' : claimableBalance,'approved':approved,'pid': i,'userValue':userValue
                    }

      _farms.push(farmData)
    }

    setFarms(_farms)
    console.log(_farms)
    setFarmsValue(_farmsValue)
    updatePortfolioValue()
  }



  
    
    return (
      
      <div className="mainArea">
        <div className="backgroundImage">
          <div className="layer" style={{ display: 'flex', minHeight: '400px' }}>
          <SidebarElement
            activePage = {activePage} 
            address={address}
            addressShort={addressShort}
            changeView ={changeView}
            chainName = {chainName}
            farmActive = {farmActive}
            fullProtocolIntegration = {fullProtocolIntegration}
            showAccount = {showAccount}
            GovernanceToken = {GovernanceToken}
            GovernanceTokenBalance = {GovernanceTokenBalance}
            GovernanceTokenTotalBalance = {GovernanceTokenTotalBalance}
            IDTBalanceWei = {IDTBalanceWei}
            loginWeb3 = {loginWeb3}
            outputNumber = {outputNumber}
            sleep = {sleep}
            GovernanceTokenTotalBalanceAllChains = {GovernanceTokenTotalBalanceAllChains}
          />
            <div className="w-100 text-light m-0 p-0">


              <Navbar
                activePage = {activePage} 
                address={address}
                addressShort={addressShort}
                changeView ={changeView}
                chainName = {chainName}
                showAccount = {showAccount}
                GovernanceToken = {GovernanceToken}
                GovernanceTokenBalance = {GovernanceTokenBalance}
                GovernanceTokenTotalBalance = {GovernanceTokenTotalBalance}
                IDTBalanceWei = {IDTBalanceWei}
                loginWeb3 = {loginWeb3}
                outputNumber = {outputNumber}
                sleep = {sleep}
                GovernanceTokenTotalBalanceAllChains = {GovernanceTokenTotalBalanceAllChains}
              />
              
              <Routes>
                  <Route path="/" element={
                    fullProtocolIntegration && loggedIn?
                        <Mainpage
                          address = {address}
                          assets = {assets}
                          assetDetails={assetDetails}
                          assetFactory = {assetFactory}
                          closeMessageBox = {closeMessageBox}
                          ERC20_ABI = {ERC20_ABI}
                          farmActive = {farmActive}
                          farms = {farms}
                          GovernanceToken_Address = {GovernanceToken_Address}
                          GovernanceTokenBalance = {GovernanceTokenBalance} 
                          GovernanceTokenStakeBalance = {GovernanceTokenStakeBalance}
                          GovernanceTokenVestedStakeBalance = {GovernanceTokenVestedStakeBalance} 
                          GovernanceToken = {GovernanceToken}
                          ISSPrice = {ISSPrice}
                          ISSMarketCap = {ISSMarketCap}
                          ISSMarketCapCurrent = {ISSMarketCapCurrent}
                          loadBlockchainData={loadBlockchainData}
                          openMessageBox = {openMessageBox}
                          outputNumber = {outputNumber}
                          pools = {pools}
                          sleep = {sleep}
                          totalLockedValue = {totalLockedValue}
                          updateFarms = {updateFarms}
                          web3 = {web3}
                          />
                          :
                          loggedIn?
                            <ChangeChain />
                            :
                            ''
                          

                  } />
                  <Route path="/portfolio" element={
                    fullProtocolIntegration && loggedIn?
                      <Portfolio
                        address = {address}
                        assets = {assets}
                        assetDetails={assetDetails}
                        assetFactory = {assetFactory}
                        assetValue = {assetValue}
                        blockchainDataLoaded = {blockchainDataLoaded}
                        farms = {farms}
                        farmActive = {farmActive}
                        farmsValue = {farmsValue}
                        GovernanceTokenTotalBalance = {GovernanceTokenTotalBalance}
                        ISSPrice = {ISSPrice}    
                        LPValue = {LPValue}
                        MarketFactory = {MarketFactory_nm}
                        MarketPair_ABI = {MarketPair_ABI}               
                        outputNumber = {outputNumber}     
                        pools = {pools} 
                        totalValue = {totalValue}  
                        timeStampToDate = {timeStampToDate}
                        USDC_Address = {USDC_Address}
                        USDCBalance = {USDCBalance}
                        USDDecimals = {USDDecimals}
                        web3 = {web3_nm}
                      />
                      :
                          loggedIn?
                            <ChangeChain />
                            :
                            ''
                    } 
                  />
                  <Route path="/pool" element={
                    fullProtocolIntegration && loggedIn?
                      <Pool
                        address = {address}
                        approveToken = {approveToken}
                        assets = {assets}
                        assetDetails={assetDetails}
                        assetFactory = {assetFactory_nm}
                        checkUSDAllowanceAssetFactory = {checkUSDAllowanceAssetFactory}
                        closeMessageBox = {closeMessageBox}
                        ERC20_ABI = {ERC20_ABI} 
                        expiredAssets = {expiredAssets}
                        GovernanceToken_Address = {GovernanceToken_Address}
                        GovernanceTokenBalance = {GovernanceTokenBalance} 
                        loadBlockchainData={loadBlockchainData}
                        loadUSDBalance = {loadUSDBalance}
                        liveAssets = {liveAssets}
                        MarketRouter = {MarketRouter_nm}
                        MarketRouter_Address = {MarketRouter_Address}
                        MarketFactory = {MarketFactory_nm}
                        MarketPair_ABI = {MarketPair_ABI}
                        openMessageBox = {openMessageBox}
                        outputNumber = {outputNumber}
                        pools = {pools}
                        saveSlippagePreference = {saveSlippagePreference}
                        saveTrxTimePreference = {saveTrxTimePreference}
                        sleep = {sleep}
                        slippage = {slippage}
                        transactWithContract = {transactWithContract}
                        trxTime = {trxTime}
                        updateAssetBalance = {updateAssetBalance}
                        updateLPPair = {updateLPPair}
                        updateLPPairWithAddress = {updateLPPairWithAddress}
                        updateAssetBalanceWithAddress = {updateAssetBalanceWithAddress}
                        updatePortfolioValue = {updatePortfolioValue}
                        USDC = {USDC_nm}
                        USDC_Address = {USDC_Address}
                        USDCAllowance = {USDCAllowance}
                        USDCBalance = {USDCBalance}
                        USDDecimals = {USDDecimals}
                        web3 = {web3_nm}
                      />
                      :
                          loggedIn?
                            <ChangeChain />
                            :
                            ''
                  } 
                  />
                  <Route path="/farm" element={
                    fullProtocolIntegration && loggedIn?
                      <Farm
                        address = {address}            
                        approveToken={approveToken}
                        blockchainDataLoaded = {blockchainDataLoaded}
                        closeMessageBox = {closeMessageBox}
                        ERC20_ABI = {ERC20_ABI}
                        farms = {farms}        
                        loadBlockchainData={loadBlockchainData}
                        loadUSDBalance = {loadUSDBalance}
                        MasterChef = {MasterChef_nm}
                        MasterChef_Address = {MasterChef_Address}
                        openMessageBox = {openMessageBox}
                        outputNumber = {outputNumber}
                        sleep = {sleep}
                        transactWithContract = {transactWithContract}
                        updateFarms = {updateFarms}
                        updateISSData = {updateISSData}
                        updatePortfolioValue = {updatePortfolioValue}
                        web3 = {web3_nm}
                        web3_nm = {web3_nm}
                      /> 
                      :
                        loggedIn?
                          <ChangeChain />
                          :
                          ''
                  } 
                  />
                  <Route path="/mint" element={
                    fullProtocolIntegration && loggedIn?
                      <Factory 
                        address = {address}
                        approveToken={approveToken}
                        assets = {assets}
                        assetDetails={assetDetails}
                        assetFactory = {assetFactory_nm}
                        assetFactory_Address = {assetFactory_Address}
                        checkUSDAllowanceAssetFactory = {checkUSDAllowanceAssetFactory}
                        closeMessageBox = {closeMessageBox}
                        expiredAssets = {expiredAssets}
                        liveAssets = {liveAssets}
                        loadBlockchainData={loadBlockchainData}
                        loadUSDBalance = {loadUSDBalance}
                        MarketFactory = {MarketFactory_nm}
                        MarketPair_ABI = {MarketPair_ABI}
                        openMessageBox = {openMessageBox}               
                        outputNumber = {outputNumber}     
                        pools = {pools}
                        sleep = {sleep} 
                        totalValue = {totalValue}
                        transactWithContract = {transactWithContract}
                        updateAssetBalance = {updateAssetBalance}
                        USDC_Address = {USDC_Address}
                        USDCAllowance = {USDCAllowance} 
                        USDCBalance = {USDCBalance}
                        USDDecimals = {USDDecimals}
                        USDC = {USDC_nm}   
                        web3 = {web3_nm}
                        />
                        :
                        loggedIn?
                          <ChangeChain />
                          :
                          ''                
                       
                  } 
                  />
                  <Route path="/trade" element={
                    fullProtocolIntegration && loggedIn?
                      <Market
                        address = {address}
                        approveToken={approveToken}
                        assets = {assets}
                        assetDetails={assetDetails}
                        loadBlockchainData={loadBlockchainData}
                        GovernanceToken_Address = {GovernanceToken_Address}          
                        GovernanceTokenBalance = {GovernanceTokenBalance} 
                        USDCBalance = {USDCBalance}
                        USDC_Address = {USDC_Address}
                        USDDecimals = {USDDecimals}
                        USDT = {USDC_nm}
                        MarketRouter = {MarketRouter_nm}
                        MarketRouter_Address = {MarketRouter_Address}
                        MarketFactory = {MarketFactory_nm}
                        MarketPair_ABI = {MarketPair_ABI}
                        ERC20_ABI = {ERC20_ABI} 
                        web3 = {web3_nm}
                        openMessageBox = {openMessageBox}
                        closeMessageBox = {closeMessageBox}
                        outputNumber = {outputNumber}
                        sleep = {sleep}
                        loadUSDBalance = {loadUSDBalance}
                        saveSlippagePreference = {saveSlippagePreference}
                        saveTrxTimePreference = {saveTrxTimePreference}
                        slippage = {slippage}
                        transactWithContract = {transactWithContract}
                        trxTime = {trxTime}
                        updateAssetBalanceWithAddress = {updateAssetBalanceWithAddress}
                        updateISSData = {updateISSData}
                        updatePortfolioValue = {updatePortfolioValue}
                      /> 
                      :
                      loggedIn?
                          <ChangeChain />
                          :
                          ''
                  } 
                  />
                  <Route path="/redeem" element={
                    fullProtocolIntegration && loggedIn?
                      <BurnAssets
                        address = {address}
                        approveToken={approveToken}
                        assets = {assets}
                        assetDetails={assetDetails}
                        assetFactory = {assetFactory_nm}
                        ERC20_ABI = {ERC20_ABI} 
                        loadBlockchainData={loadBlockchainData}
                        loadUSDBalance = {loadUSDBalance}
                        openMessageBox = {openMessageBox}
                        outputNumber = {outputNumber}
                        sleep = {sleep}
                        transactWithContract = {transactWithContract}
                        updateAssetBalanceWithAddress = {updateAssetBalanceWithAddress}
                        updatePortfolioValue = {updatePortfolioValue}
                        updateAssetBalance = {updateAssetBalance}
                        web3 = {web3_nm}
                      />
                      :
                      loggedIn?
                        <ChangeChain />
                        :
                        ''
                  } 
                  />
                  <Route path="/governance" element={
                    fullProtocolIntegration && loggedIn?
                      <Governance
                        address = {address}
                        web3 = {web3_nm}
                        assets = {assets}
                        assetDetails={assetDetails}
                        assetFactory = {assetFactory_nm}
                        GovernanceTokenStakeBalance = {GovernanceTokenStakeBalance}
                        loadBlockchainData={loadBlockchainData}
                        updateExpiryVotes = {updateExpiryVotes}
                        updateFreezeVotes = {updateFreezeVotes}
                        checkRewards = {checkRewards}
                        VoteMachine = {VoteMachine_nm}
                        ERC20_ABI = {ERC20_ABI} 
                        openMessageBox = {openMessageBox}
                        closeMessageBox = {closeMessageBox}
                        outputNumber = {outputNumber}
                        DAO = {DAO_nm}
                        Upgrader = {Upgrader_nm}
                        timeStampToDate = {timeStampToDate}
                        timeStampToDateAndTime = {timeStampToDateAndTime}
                        transactWithContract = {transactWithContract}
                        sleep = {sleep}
                        chain = {chainName}
                      />
                      :
                      loggedIn?
                        <ChangeChain />
                        :
                        ''
                  } 
                  />
                  <Route path="/ISS" element={
                    fullProtocolIntegration && loggedIn?
                      <GovernanceTokenPage
                        address = {address}
                        approveToken = {approveToken}
                        assets = {assets}
                        assetDetails={assetDetails}
                        assetFactory = {assetFactory}
                        chain = {chainName}
                        checkRewards = {checkRewards}
                        closeMessageBox = {closeMessageBox}
                        ERC20_ABI = {ERC20_ABI}
                        GovernanceToken_ABI = {GovernanceToken_ABI}
                        GovernanceToken_Address = {GovernanceToken_Address}
                        GovernanceTokenBalance = {GovernanceTokenBalance} 
                        GovernanceTokenStakeBalance = {GovernanceTokenStakeBalance}
                        GovernanceTokenVestedStakeBalance = {GovernanceTokenVestedStakeBalance} 
                        GovernanceToken = {GovernanceToken_nm}
                        GovernanceTokenTotalBalanceAllChains = {GovernanceTokenTotalBalanceAllChains}
                        IDT = {IDT_nm}
                        IDTBalanceWei = {IDTBalanceWei}  
                        ISSSupply = {ISSSupply}
                        ISSSupplyCurrent = {ISSSupplyCurrent}
                        ISSPrice = {ISSPrice}
                        loadBlockchainData={loadBlockchainData}
                        lockDate = {lockDate}
                        openMessageBox = {openMessageBox}
                        outputNumber = {outputNumber}
                        RewardsMachine = {RewardsMachine_nm}
                        sleep = {sleep}
                        testnet = {testnet}
                        timeStampToDate = {timeStampToDate}
                        timeStampToDateAndTime = {timeStampToDateAndTime}
                        totalVeISSSupply = {TotalVeISSSupply}
                        transactWithContract = {transactWithContract}
                        updateAssetBalanceWithAddress = {updateAssetBalanceWithAddress}
                        veISSBalance = {veISSBalance}
                        VotingEscrow = {VotingEscrow_nm}
                        web3 = {web3_nm}                         
                      />
                      :
                      loggedIn?
                        <ChangeChain />
                        :
                        ''
                  } 
                  />
                  <Route path="/IDT" element={
                    fullProtocolIntegration && loggedIn?
                      <IDTConversion
                        address = {address}              
                        approveToken={approveToken}
                        assets = {assets}
                        assetDetails={assetDetails}
                        assetFactory = {assetFactory_nm}
                        chain = {chainName}
                        closeMessageBox = {closeMessageBox}
                        ERC20_ABI = {ERC20_ABI}
                        GovernanceToken_ABI = {GovernanceToken_ABI}
                        GovernanceToken_Address = {GovernanceToken_Address}
                        GovernanceTokenBalance = {GovernanceTokenBalance} 
                        GovernanceTokenStakeBalance = {GovernanceTokenStakeBalance}
                        GovernanceTokenVestedStakeBalance = {GovernanceTokenVestedStakeBalance} 
                        GovernanceToken = {GovernanceToken_nm}
                        GovernanceTokenTotalBalanceAllChains = {GovernanceTokenTotalBalanceAllChains}
                        IDT = {IDT_nm}
                        IDTBalanceWei = {IDTBalanceWei}  
                        ISSSupply = {ISSSupply}
                        ISSPrice = {ISSPrice}
                        loadBlockchainData={loadBlockchainData}
                        loadLimitedBlockchainData = {loadLimitedBlockchainData}
                        lockDate = {lockDate}
                        openMessageBox = {openMessageBox}
                        outputNumber = {outputNumber}
                        RewardsMachine = {RewardsMachine_nm}
                        sleep = {sleep}
                        testnet = {testnet}
                        timeStampToDate = {timeStampToDate}
                        totalVeISSSupply = {TotalVeISSSupply}
                        transactWithContract = {transactWithContract}
                        updateAssetBalanceWithAddress = {updateAssetBalanceWithAddress}
                        veISSBalance = {veISSBalance}
                        VotingEscrow = {VotingEscrow_nm}
                        web3 = {web3_nm}
                      />
                      :
                      loggedIn?
                        <ChangeChain />
                        :
                        ''
                  } 
                  />
                  <Route path="/easyFarm" element={
                    fullProtocolIntegration && loggedIn?
                      <EasyFarm
                        address = {address}
                        assets = {assets}
                        pools = {pools}
                        assetDetails={assetDetails}
                        loadBlockchainData={loadBlockchainData}
                        GovernanceToken_Address = {GovernanceToken_Address}
                        GovernanceTokenBalance = {GovernanceTokenBalance} 
                        USDCBalance = {USDCBalance}
                        USDC_Address = {USDC_Address}
                        USDDecimals = {USDDecimals}
                        USDT = {USDC_nm}
                        MarketRouter = {MarketRouter_nm}
                        MarketRouter_Address = {MarketRouter_Address}
                        MarketFactory = {MarketFactory_nm}
                        MarketPair_ABI = {MarketPair_ABI}
                        ERC20_ABI = {ERC20_ABI} 
                        web3 = {web3_nm}
                        web3_nm = {web3_nm}
                        openMessageBox = {openMessageBox}
                        closeMessageBox = {closeMessageBox}
                        outputNumber = {outputNumber}
                        sleep = {sleep}
                        loadUSDBalance = {loadUSDBalance}
                        updateLPPair = {updateLPPair}
                        updateLPPairWithAddress = {updateLPPairWithAddress}
                        updateAssetBalanceWithAddress = {updateAssetBalanceWithAddress}
                        updatePortfolioValue = {updatePortfolioValue}
        
                        USDCAllowance = {USDCAllowance}
                        assetFactory = {assetFactory_nm}
                        liveAssets = {liveAssets}
                        expiredAssets = {expiredAssets}
                        checkUSDAllowanceAssetFactory = {checkUSDAllowanceAssetFactory}
                        updateAssetBalance = {updateAssetBalance}
                        roundDown = {roundDown}
                      />
                      :
                      loggedIn?
                        <ChangeChain />
                        :
                        ''
                  } 
                  />
                  <Route path="/test" element={
                    fullProtocolIntegration && loggedIn?
                      <Test
                        address = {address}
                        USDT = {USDC_nm}
                        openMessageBox = {openMessageBox}
                        closeMessageBox = {closeMessageBox}
                        loadBlockchainData={loadBlockchainData}
                        sleep = {sleep}
                        loadUSDBalance = {loadUSDBalance}
                        chain = {chainName}
                        transactWithContract = {transactWithContract}
                      />
                      :
                      loggedIn?
                        <ChangeChain />
                        :
                        ''
                  } 
                  />
              </Routes>


              

              
              
              
              


              
              {messageBoxVisible ? <MessageBox content={messageBoxContent}/> : ''}
              <div className="container">
              <div>&nbsp;</div> 
              <div>&nbsp;</div> 
              <div>&nbsp;</div> 
              <div>&nbsp;</div> 
              
            </div>
            </div>
            </div>
        </div>
        
        
 
        <Modal 
          dialogClassName="loadingBlockchainModalOuter" show={loadingBlockchainData} centered
        >
          <div 
          style={{border:"2px"}}
          className="loadingBlockchainModal">
            <div className="row">
              <div className="col-12 center p-4">
                <img className="center" src={panda} alt="Logo" height="200"/>
              </div>
            </div>
            <div className="row">
              <div className="col-12 h5 text-center">
                  <div className="spinner-border text-accent text-center ml-2" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
              </div>
                <div className="col-12 pt-2 pb-3 mr-3 text-center">
                  Loading Blockchain data...
                </div>
            </div>
          </div>
        </Modal>
        
        <Modal className = "rounded" show={wrongNetworkMessage}>
          <div className="p-3 bg-dark text-light border border-accent rounded">
            <div className="row m-4"></div>
            <div className="row">
              <div className="col text-center">
                <p>Wrong Network selected.</p>
                Please switch to Scroll or Scroll Sepolia Testnet.
              </div>
            </div>
            <div className="row m-4"></div>
          </div>
        </Modal>
      </div>
      
    )
}

export default App;
