import '../styles/factory.css';
import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";
import arrowDown from '../img/arrow-down.png'; 
import Zoom from '@successtar/react-reveal/Zoom';



class Factory extends Component {
    

    state = { 
        assets: ['wait'],
        modalOpen: false,
        selectedAsset : 'Select an asset',
        submitButtonVisible: false,
        errorButtonVisible: false,
        errorButtonMessage:"",
        USDCBalance: 0,
        chooseAssetModalOpen: false,
        filteredAssets: [],
    }

    async componentDidMount() {
        //if (this.props.USDCAllowance != 'undefined') {this.setState({USDCAllowance:this.props.USDCAllowance})}

        this.setState({
            USDCAllowance: parseInt(this.props.USDCAllowance),
            assetDetails: this.props.assetDetails,
            USDCBalance: this.props.USDCBalance,
        });
        console.log(parseInt(this.props.USDCAllowance))
        if (parseInt(this.props.USDCAllowance) > 0 ){
            console.log(parseInt(this.props.USDCAllowance))
            this.setState({approvalButtonVisible: false});
            this.setState({confirmButtonVisible: true});
            this.setState({confirmButtonBlockedVisible: false})
        }
        else {
            this.setState({approvalButtonVisible: true});
            this.setState({confirmButtonVisible: false});
            this.setState({confirmButtonBlockedVisible: true})
            }
    };

    checkUSDAllowanceAssetFactory = async () => {
        // check if the the allowance has been set for the AssetFactory contract to spend USDT
       let allowance = await this.props.USDC.methods.allowance(this.props.address, this.props.assetFactory_Address).call()
       this.setState({USDCAllowance:allowance})
       console.log(this.state.USDCAllowance)
 
     };


    openModal = async () => {
       
        if (this.props.USDCAllowance !== "0"){
            this.setState({approvalButtonVisible: false});
            this.setState({confirmButtonVisible: true});
            this.setState({confirmButtonBlockedVisible: false})
        }
        else {
            this.setState({approvalButtonVisible: true});
            this.setState({confirmButtonVisible: false});
            this.setState({confirmButtonBlockedVisible: true})
            }
        
        let selectedAsset = this.state.selectedAsset;
        let investmentAmount = document.getElementById('amountToStake').value
        let AssetDetails  = await this.props.assetFactory.methods.getAsset(selectedAsset).call()
        let upperLimit = Number(AssetDetails.upperLimit)/1000
        let tokenAmount = investmentAmount / upperLimit
        console.log("Token amount:",tokenAmount)
        console.log(AssetDetails)
        this.setState({ modalOpen: true })
        this.setState({ tokenAmount})
        
        this.setState({selectedAsset})
    };

    closeModal = () => this.setState({ modalOpen: false });

    confirmTransaction = async() => {
        this.closeModal()
        let message = "Transmitting to the blockchain. Waiting for confirmation"
        this.props.openMessageBox(message)
        //await this.mintAssets(this.state.selectedAsset,document.getElementById('amountToStake').value)
        let amountToStake = this.props.web3.utils.toBigInt(document.getElementById('amountToStake').value) * this.props.web3.utils.toBigInt(1e18)
        let assetToMint = this.state.selectedAsset
        let result = await this.props.transactWithContract('assetFactory','mintAssets',[assetToMint,amountToStake])
        console.log(result)
        await this.props.updateAssetBalance(assetToMint);
        await this.props.loadUSDBalance();
        document.getElementById('amountToStake').value = 0
        
    }

    
    approveAccount = async() =>{  
        console.log("Approving Honey",this)
        let message = "Approving to spend Tokens"
        this.props.openMessageBox(message)
        let tx = await this.props.approveToken(this.props.USDC_Address,this.props.assetFactory._address)
        if (tx['status'] == "success"){
            this.changeAmount();
        }
        //await this.changeAmount();
        return ("Approved")
    };

    setMaxAmount = async() =>{
        let amount = 0
        if (typeof(this.props.USDCBalance) != 'undefined'){ 
            amount = parseInt(this.props.USDCBalance)
        }
        document.getElementById('amountToStake').value = amount
        this.changeAmount()
        return
    };

    changeAmount = async() =>{
        console.log("amount")
        let allowance = await this.props.USDC.methods.allowance(this.props.address, this.props.assetFactory_Address).call()
        await this.setState({USDCAllowance: parseInt(allowance)})
        console.log(this.state.USDCAllowance)
        
        let amount = await document.getElementById('amountToStake').value;
        await this.setState({mintAmount:amount})
        
        if (amount > parseInt(this.props.USDCBalance)) {
            this.setState({errorButtonVisible: true})
            this.setState({errorButtonMessage: "Balance too low"})
        }
        else if (this.state.selectedAsset === "Select an asset") {
            this.setState({errorButtonVisible: true})
            this.setState({errorButtonMessage: "Select an asset"})
        }
        else {
            this.setState({errorButtonVisible: false})
        }

    }

    openChooseAssetModal=()=>{
        let assets = [];
        for (let key in this.props.assetDetails) {
            if (this.props.assetDetails[key]['frozen'] === false){

                let balance1 = this.props.assetDetails[key]['tokenBalance1'];
                let balance2 = this.props.assetDetails[key]['tokenBalance2'];
                let asset = [key,balance1,balance2, this.props.assetDetails[key]['name'], this.props.assetDetails[key]['upperLimit']];
                assets.push(asset);
                console.log(asset)
            }
            
        }

        this.setState({chooseAssetModalOpen: true })
        this.setState({assets:assets})
        this.setState({filteredAssets: assets})  

        
    };
    closeChooseAssetModal=()=>{
        this.setState({chooseAssetModalOpen: false })
    }

    listMintableAssets() {
        let assetOptions = this.state.filteredAssets.map((element,index) =>
                <div key={index} className="assetSelectList" role="button" onClick={()=>this.selectAsset(element[0])}>
                    <div className="row">
                        <div className="col-3"><b>{element[0]}</b></div>
                        <div className="col text-right"><b>{element[3]}</b></div>
                    </div>
                    <div className="row">
                        <div className="col">Balance: {this.props.outputNumber(element[1],4)} (long) / {this.props.outputNumber(element[2],4)} (short)</div>
                        <div className="col text-right">UL: {this.props.outputNumber(Number(element[4])/1000,0)} HONEY</div>
                    </div>
                </div>
        );
        return(assetOptions)
    }

    filterAssets(){
        let filteredAssets =[];
        let searchTerm = document.getElementById('search').value.toLowerCase()
        for (let i = 0; i < this.state.assets.length; ++i) {
            if (this.state.assets[i][3].toLowerCase().includes(searchTerm) || this.state.assets[i][0].toLowerCase().includes(searchTerm)){
                filteredAssets.push(this.state.assets[i])
            }
            
        }
        this.setState({filteredAssets:filteredAssets})


    }

    selectAsset = async(asset) =>{
        console.log(asset)
        document.getElementById('amountToStake').value = 0
        await this.setState({"selectedAsset":asset});
        this.closeChooseAssetModal();
    };

    render() { 
        
              
        return ( 
            
            <div className="row w-100">
                <div className="container-fluid m-3">

                    <Modal
                        show={this.state.chooseAssetModalOpen} onHide={this.closeChooseAssetModal}>
                        <Modal.Header className="modalHeader" closeButton>
                            <Modal.Title>Select ISSUAA Asset pair to mint</Modal.Title>   
                        </Modal.Header>
                        
                        <Modal.Body style={{
                          maxHeight: 'calc(100vh - 210px)',
                          overflowY: 'auto'
                         }} 
                        >
                            <div className="row p-3 pr-3 my-auto">
                                <input type="text" className="searchField col w-100 " id="search" placeholder="Search" onChange={() =>this.filterAssets()}></input>
                                
                            </div>
                            <div className="list-group list-assets">
                                {this.listMintableAssets()}
                            </div>
                        </Modal.Body>                        
                    </Modal>

                    <Modal show={this.state.modalOpen} onHide={this.closeModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>You will receive:</Modal.Title>
                        </Modal.Header>
                            <Modal.Body>{this.props.outputNumber(parseFloat(this.state.tokenAmount),4)} {this.state.selectedAsset} (long) and i{this.state.selectedAsset}(short) tokens.</Modal.Body>
                        <Modal.Footer>
                            {this.state.approvalButtonVisible ? <div><Button variant="accent" id="buttonRounded"onClick={this.approveAccount}>Approve</Button></div> : <div></div>}
                            {this.state.confirmButtonVisible ? <div><Button variant="fuchsia" id="buttonRounded"onClick={this.confirmTransaction}>Mint</Button></div> : <div></div>}
                            {this.state.confirmButtonBlockedVisible ? <div><Button variant="anthracite" id="buttonRounded">Convert</Button></div> : <div></div>}
                            
                        </Modal.Footer>
                    </Modal>

                    <div className="row">
                        <div className="col"></div>
                        <div className="col-5">
                            <div 
                                style ={{
                                    fontFamily: "PosteramaRegular",
                                    letterSpacing: "0.1rem",
                                    fontSize: "3.5vw",
                                    color:"white",
                                    paddingLeft: "2vw",
                                    marginBottom: "2vh",
                                }}
                            >
                                Mint Assets
                            </div>
                            <div id="mainBox" className="container text-light p-2">
                                <div id=""  className="container p-4 text-light rounded-top">
                                    
                                    <div className="subBox px-4 py-4 ">                                
                                        <div className="row h4">
                                            <div className="col mb-4">Select an ISSUAA Asset pair to mint:</div>
                                            <Button className="btn my-auto btn-accent w-100 mx-3 my-2 buttonText" id="buttonRounded" onClick={this.openChooseAssetModal}>
                                                <div>{this.state.selectedAsset} <img src={arrowDown} alt="switch" height="15"/>   </div>
                                            </Button>
                                        </div>


                                    </div>
                                    <div className="container p-4">
                                    
                                    </div>
                                    
                                        <div className="subBox px-4 py-4 "> 
                                        <div className="row">
                                            <div className="col">HONEY amount to deposit:</div>
                                            <div onClick={this.setMaxAmount} role="button" className="col align-self-end textBalance" key={this.props.USDCBalance}>Balance: <span className="tradeBalance">{typeof(this.props.USDCBalance) != 'undefined' ? this.props.outputNumber(parseInt(this.props.USDCBalance),0)+" HONEY (Max)" : ''}</span></div>
                                        </div>
                                        <div className="input-group mb-3">
                                            <input placeholder="0.00" className="inputCustom" type='text' id='amountToStake' onChange={this.changeAmount}></input>
                                                
                                        </div>
                                        
                                    </div>
                                    
                                </div>    
                                
                                <div id=""  className="container px-4 pr-4 pt-1 py-1 text-black rounded-bottom">
                                    {this.state.mintAmount > this.state.USDCAllowance/1e18 ? 
                                        <Button className="btn w-100 issuaaButtonPink mb-2" variant="warning" onClick={this.approveAccount}>
                                            Approve
                                        </Button> 
                                        : <div></div>
                                    }
                                    
                                    {this.state.errorButtonVisible ? <Button disabled className="btn w-100 issuaaButtonDeactivated mb-2">{this.state.errorButtonMessage}</Button> : <div></div>}
                                    {this.state.mintAmount < this.state.USDCAllowance/1e6 &&  this.state.errorButtonVisible === false ? 
                                        <Button className="btn w-100 issuaaButton" onClick={this.openModal}>
                                            Submit
                                        </Button> 
                                    : <div></div>
                                    }
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col"></div>
                    </div>
                    
                </div>
            </div>
            
         );
    }
}
 
export default Factory;