import '../styles/portfolio.css';
import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";
import info from '../img/ISSUAA-i.png';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Zoom from '@successtar/react-reveal/Zoom';

class Portfolio extends Component {
    state = { 
    assetDetails: null,       
    };

    async componentDidMount() {
        
        this.setState({
            assets: ['wait'],
            freezeModalOpen: false,
            assetToFreeze: "",
            assetToVote: "",
            voteModalOpen: false,
            assetToClose: "",

            closeModalOpen: false,
            finalPriceModalOpen: false ,
            assetToVoteOnFinalPrice: "",
            initiateExpiryVoteModalOpen: false ,
            assetToInitiateExpiryVote: "",
            closeExpiryVoteModalOpen: false,
            assetToCloseExpiryVote: "",
            assetDetails: this.props.assetDetails,
           
        });
        console.log(this.props.assetDetails)
        console.log(Date.now())
        await this.loadPortfolio();
    };

    getAssets() {
        let assets = this.props.assets;
        return assets;
    };
    getAssetsAll() {
        let allAssets = [];
        let assets = this.props.assets;
        for (let i = 0; i < assets.length; ++i) {
            console.log(assets[i])
            //newAsset = this.props.assetDetails[]
            allAssets.push(assets[i])
            allAssets.push("i"+assets[i])
        }
        return allAssets;
    };

    
     loadPortfolio = async() => {
        var portfolio = {}
        var portfolioAssets =[]
        portfolio["ISS"] = {}
        console.log(this.props.GovernanceTokenTotalBalance)
        if (this.props.GovernanceTokenTotalBalance >0.01){
            portfolio["ISS"]['name'] = "Issuaa Protocol Token"
            portfolio["ISS"]['balance'] = this.props.GovernanceTokenTotalBalance
            portfolio["ISS"]['expiryDate'] = ""
            portfolio["ISS"]['upperLimit'] = ""
            portfolio["ISS"]['isFrozen'] = ""
            portfolio["ISS"]['price'] = this.props.ISSPrice
            portfolio["ISS"]['portfolioValue'] = this.props.ISSPrice * this.props.GovernanceTokenTotalBalance
            portfolioAssets.push("ISS")
        }

        portfolio["HONEY"] = {}
        if (this.props.USDCBalance >0.01){
            portfolio["HONEY"]['name'] = "HONEY"
            portfolio["HONEY"]['balance'] = this.props.USDCBalance
            portfolio["HONEY"]['expiryDate'] = ""
            portfolio["HONEY"]['upperLimit'] = ""
            portfolio["HONEY"]['isFrozen'] = ""
            portfolio["HONEY"]['price'] = 1
            portfolio["HONEY"]['portfolioValue'] = this.props.USDCBalance
            portfolioAssets.push("HONEY")
        }

        var assets = this.getAssets();
        
        if (typeof(this.props.assetDetails) != 'undefined' && typeof(this.props.assets) != 'undefined' && typeof(this.props.USDC_Address) && typeof(this.props.MarketFactory) != 'undefined'){
            console.log(this.state.portfolio)
            console.log(this.state.portfolioAssets)
            for (let i = 0; i < assets.length; ++i) {
                
                console.log(assets[i]);
                let asset=assets[i]
                console.log(this.props.assetDetails[asset]['tokenBalance1'])
                if (parseFloat(this.props.assetDetails[asset]['tokenBalance1'])>0.0000001) {
                    // Get the token price
                    let tokenAddress = this.props.assetDetails[asset][0]
                    console.log(this.props)
                    let pair = await this.props.MarketFactory.methods.getPair(tokenAddress,this.props.USDC_Address).call();
                    let MarketPair = new this.props.web3.eth.Contract(this.props.MarketPair_ABI,pair);
                    let reserves = await MarketPair.methods.getReserves().call();
                    let token0 = await MarketPair.methods.token0().call();
                    let tokenPrice
                    if (token0 === this.props.USDC_Address) {
                        tokenPrice = Number(reserves[0])*(10**(18-this.props.USDDecimals))/Number(reserves[1])
                    }
                    else{
                        tokenPrice = Number(reserves[1])/Number(reserves[0])*(10**(18-this.props.USDDecimals))
                    }


                    

                    portfolio[asset] = {}
                    portfolio[asset]['name'] = this.props.assetDetails[asset]['name']
                    portfolio[asset]['balance'] = this.props.assetDetails[asset]['tokenBalance1']
                    portfolio[asset]['expiryDate'] = this.props.assetDetails[asset]['expiryTime']
                    portfolio[asset]['upperLimit'] = this.props.assetDetails[asset]['upperLimit']
                    portfolio[asset]['isFrozen'] = this.props.assetDetails[asset]['frozen']
                    let price = tokenPrice
                    portfolio[asset]['price'] = price
                    portfolio[asset]['portfolioValue'] = parseFloat(this.props.assetDetails[asset]['tokenBalance1']) * price
                    portfolioAssets.push(assets[i])
                }
                if (parseFloat(this.props.assetDetails[asset]['tokenBalance2'])>0.0000001) {
                    // Get the token price
                    let tokenPrice1
                    let tokenAddress = this.props.assetDetails[asset][1]
                    let pair = await this.props.MarketFactory.methods.getPair(tokenAddress,this.props.USDC_Address).call();
                    let MarketPair = new this.props.web3.eth.Contract(this.props.MarketPair_ABI,pair);
                    let reserves = await MarketPair.methods.getReserves().call();
                    let token0 = await MarketPair.methods.token0().call();
                    if (token0 === this.props.USDC_Address) {
                        tokenPrice1 = Number(reserves[0])*(10**(18-this.props.USDDecimals))/Number(reserves[1])
                    }
                    else{
                        tokenPrice1 = Number(reserves[1])/Number(reserves[0])*(10**(18-this.props.USDDecimals))
                    }

                    portfolio["i"+asset] = {}
                    portfolio["i"+asset]['name'] = "Inverse "+this.props.assetDetails[asset]['name']
                    portfolio["i"+asset]['balance'] = this.props.assetDetails[asset]['tokenBalance2']
                    portfolio["i"+asset]['expiryDate'] = this.props.assetDetails[asset]['expiryTime']
                    portfolio["i"+asset]['upperLimit'] = this.props.assetDetails[asset]['upperLimit']
                    portfolio["i"+asset]['isFrozen'] = this.props.assetDetails[asset]['frozen']
                    let price = tokenPrice1
                    portfolio["i"+asset]['price'] = price
                    portfolio["i"+asset]['portfolioValue'] = parseFloat(this.props.assetDetails[asset]['tokenBalance2']) * price
                    portfolioAssets.push("i"+assets[i])
                }

            }
            console.log(portfolio)
            console.log(portfolioAssets)
            this.setState({portfolio})
            this.setState({portfolioAssets})
        }

        
    }

    timeStampToDate(timestamp) {
        var date = new Date(timestamp * 1000)
        const options = {year: '2-digit', month: '2-digit', day: '2-digit' };
        let formattedDate = date.toLocaleDateString(undefined,options);
        console.log(formattedDate)
        return(formattedDate);
    }

    render() { 

        const tooltip1 = props => (
            <Tooltip {...props}>
            Your liquidity provider tokens reflecting your assets and cash locked in the ISSUAA pools.
            </Tooltip>
        );
        const tooltip2 = props => (
            <Tooltip {...props}>
            Your ISSUAA Assets portfolio available for trading; the exception are your ISS tokens, which are staked or not vested yet.
            </Tooltip>
        );


        if (!this.state.assetDetails) {
            return <div />
        }
        //var AssetOptions = ['loading']
        var assets = this.getAssets();
        if (typeof(this.props.assets) !== 'undefined'){
            console.log(assets)
        }

        
        
        let assetOutput
        if (typeof(this.props.assetDetails) !== 'undefined' && typeof(this.props.assets) != 'undefined'  && typeof(this.state.portfolio) != 'undefined' && typeof(this.state.portfolioAssets) != 'undefined'){
            console.log(this.state.portfolio)
            console.log(this.props.assetDetails);
            assets = this.getAssetsAll();
            console.log(assets);
            console.log(this.props.assets);
            console.log(this.state.portfolioAssets)
            
            assetOutput = this.state.portfolioAssets.map((asset,index) =>

                    
                        <tr key ={index}>
                            <td className="text-left">{asset}</td>
                            <td className="text-left">{this.state.portfolio[asset]['name']}</td>
                            <td className="text-right">{this.props.outputNumber(parseFloat(this.state.portfolio[asset]['price']),2)}</td>
                            <td className="text-right">{this.props.outputNumber(parseFloat(this.state.portfolio[asset]['balance']),4)}</td>
                            <td className="text-right">{(asset !== "ISS" & asset !== "HONEY") ? this.timeStampToDate(parseInt(this.state.portfolio[asset]['expiryDate'])):'n.a.'}</td>
                            <td className="text-right">{(asset !== "ISS" & asset !== "HONEY") ? this.props.outputNumber((parseFloat(this.state.portfolio[asset]['upperLimit'])/1000),2):'n.a.'}</td>
                            {this.state.portfolio[asset]['frozen']?<td className="text-right">frozen</td>:<td className="text-right">live</td>}
                            <td className="text-right">{this.props.outputNumber(parseFloat(this.state.portfolio[asset]['portfolioValue']),0)}</td>
                            
                        </tr>

                        
               
            );  
        } ;   

        let LPOutput
        let myPools = []
        console.log(this.props.pools)
        if (typeof(this.props.pools) !== 'undefined' & this.props.assetDetails !== 'undefined'){
            console.log(this.props.pools)

            for (let i=0; i<this.props.pools.length; i++) {

                console.log(typeof(this.props.pools[i][4]))
                console.log(typeof(this.props.pools[i][5]))
                console.log(typeof(this.props.pools[i][2]))
                if (((this.props.pools[i][4]/this.props.pools[i][5])*this.props.pools[i][2])/10e18 >= 0.1){
                    myPools.push(this.props.pools[i])
                }
            console.log(myPools)
            
            LPOutput = myPools.map((pool,index) =>

                        <tr key ={index}>
                            <td className="text-left">{pool[0]}</td>
                            <td className="text-left">{pool[3]}</td>
                            <td className="text-right">{this.props.outputNumber((parseFloat(pool[4])/1e18),8)}</td>
                            <td className="text-right">{this.props.outputNumber((((parseFloat(pool[4])/parseFloat(pool[5]))*pool[8])/1e18),3)} {pool[0]} & {this.props.outputNumber((((parseFloat(pool[4])/parseFloat(pool[5]))*pool[2]*0.5)/1e18),0)} USD</td>
                            <td className="text-right">{((parseFloat(pool[4])/parseFloat(pool[5]))*100).toFixed(2)}%</td>
                            <td className="text-right">{this.props.outputNumber((((parseFloat(pool[4])/parseFloat(pool[5]))*parseFloat(pool[2])/1e18)),0)}</td>
                            
                        </tr>                        
            );  
            }        
        }
        let farmOutput
        let myFarms = []
        console.log(this.props.farms)
        if (typeof(this.props.farms) !== 'undefined' & this.props.assetDetails !== 'undefined'){
            for (let i=0; i<this.props.farms.length; i++) {
                let farm = this.props.farms[i]
                let userValue = farm['userAmount'] * farm['tvl'] / farm['totalSupply'] /1e18
                console.log(userValue)
                if (userValue >= 0.1){
                    myFarms.push(farm)
                }
            console.log(myFarms)
            
            farmOutput = myFarms.map((farm,index) =>

                        <tr key ={index}>
                            <td className="text-left">{farm['symbol']}</td>
                            <td className="text-left">{farm['name']}</td>
                            <td className="text-right">{this.props.outputNumber(farm['tokenReserves']*farm['userAmount']/farm['totalAmount']/1e18,4)} {farm['symbol']}  / {this.props.outputNumber(farm['USDCReserves']*farm['userAmount']/farm['totalAmount']/1e6,0)} USD</td>
                            <td className="text-right">{this.props.outputNumber(farm['userAmount']*100/farm['totalAmount'],0)}%</td>
                            <td className="text-right">{this.props.outputNumber(farm['maxAPR']*40,0)}% - {this.props.outputNumber(farm['maxAPR']*100,0)}%</td>
                            <td className="text-right">{this.props.outputNumber(farm['maxAPR']*40*farm['boostFactor']/1e12,0)}%</td>
                            <td className="text-right">{this.props.outputNumber(farm['userAmount'] * farm['tvl'] / farm['totalSupply'] /1e18,0)}</td>
                            
                        </tr>                        
            );  
            }        
        }        
        
       
        return ( 
            
                <div className="container-fluid">

                    <Modal show={this.state.closeModalOpen} onHide={this.closeCloseModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Close the Vote on the following Asset?</Modal.Title>
                        </Modal.Header>
                            <Modal.Body>
                               {this.state.assetToClose}
                            </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={this.initiateClose}>Yes</Button>
                            <Button variant="warning" onClick={this.closeCloseModal}>No</Button>
                        </Modal.Footer>
                    </Modal>

                    
                    <div className="row">
                        
                        <div className="col-0 col-xl-1"></div>
                        <div className="col-10">           
                            <div 
                                style ={{
                                    fontFamily: "PosteramaRegular",
                                    letterSpacing: "0.1rem",
                                    fontSize: "3.5vw",
                                    color:"white",
                                    paddingLeft: "2vw",
                                    marginBottom: "2vh",
                                }}
                            >
                                Portfolio
                            </div>
                            <div id="mainBox" className="text-light p-4">
                                <div>
                                    <div className="row">
                                        <div className="text-white h-5 p-3">
                                            <h3>Total portfolio value: {this.props.outputNumber(this.props.totalValue,0)} USD</h3>
                                        </div>
                                        <p>&nbsp;</p>
                                    </div>

                                    <div className="row">
                                        <div className="text-light h-5 pl-3 py-0">
                                            <h5>
                                                <OverlayTrigger placement="right" overlay={tooltip2}>
                                                    <img className="mr-2" src={info} alt="Info"/>
                                                </OverlayTrigger>
                                                Your Assets:                                                
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="row mainPortfolioContent">    
                                        <div className="pl-3 pr-3  w-100">
                                            <table className="w-100">
                                                <thead className="">
                                                    <th className="text-left" scope="col">Symbol</th>
                                                    <th className="text-left" scope="col">Name</th>
                                                    <th className="text-right" scope="col">Price</th>                                            
                                                    <th className="text-right" scope="col">Position</th>
                                                    <th className="text-right" scope="col">Expiry Date</th>
                                                    <th className="text-right" scope="col">Upper limit</th>
                                                    <th className="text-right" scope="col">Status?</th>
                                                    <th className="text-right" scope="col">Value (USD)</th>
                                                </thead>
                                            
                                                <tbody>
                                                    
                                                {assetOutput}
                                                <tr>
                                                    <td className="text-left"><b>Total Assets</b></td>
                                                    <td className="text-right"></td>
                                                    <td></td>                                            
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td className="text-right"><b>{this.props.outputNumber(this.props.assetValue,0)}</b></td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                    <div className="row mt-5 ">
                                        <div className="text-light h-5 pl-3 py-0">
                                            <h5>
                                                <OverlayTrigger placement="right" overlay={tooltip1}>
                                                    <img className="mr-2" src={info} alt="Info"/>
                                                </OverlayTrigger>
                                                Your liquidity provider tokens:
                                                
                                            </h5>
                                            
                                        </div>
                                    </div>
                                    <div className="row mainPortfolioContent">    
                                        <div className="pl-3 pr-3 w-100">
                                            <table className="w-100">
                                                <thead className="">
                                                    <th className="text-left" scope="col">Symbol</th>
                                                    <th className="text-left" scope="col">Name</th>
                                                    <th className="text-right" scope="col">LP-Balance</th>
                                                    <th className="text-right" scope="col">Withdrawable Assets</th>                                            
                                                    <th className="text-right" scope="col">Pool share</th>
                                                    <th className="text-right" scope="col">Value (USD)</th>
                                                </thead>
                                                <tbody>
                                                {LPOutput}
                                                <tr>
                                                    <td className="text-left"><b>Total LP tokens</b></td>
                                                    <td></td>
                                                    <td></td>                                            
                                                    <td></td>
                                                    <td></td>
                                                    <td className="text-right"><b>{this.props.outputNumber(this.props.LPValue,0)}</b></td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>  
                                    </div>

                                    {this.props.farmActive ?
                                    <div className="row mt-5">
                                        <div className="text-light h-5 pl-3 py-0">
                                            <h5>
                                                <OverlayTrigger placement="right" overlay={tooltip1}>
                                                    <img className="mr-2" src={info} alt="Info"/>
                                                </OverlayTrigger>
                                                Your liquidity provider token farms:
                                                
                                            </h5>
                                            
                                        </div>
                                    </div>
                                        :
                                        ''
                                    }

                                    {this.props.farmActive ?
                                    <div className="row">    
                                        <div className="pl-3 pr-3 w-100">
                                            <table className="w-100">
                                                <thead className="">
                                                    <th className="text-left" scope="col">Symbol</th>
                                                    <th className="text-left" scope="col">Name</th>
                                                    <th className="text-right" scope="col">Withdrawable Assets</th>                                            
                                                    <th className="text-right" scope="col">Farm share</th>
                                                    <th className="text-right" scope="col">Pool yield</th>
                                                    <th className="text-right" scope="col">Your yield</th>
                                                    <th className="text-right" scope="col">Value (USD)</th>
                                                </thead>
                                                <tbody>
                                                {farmOutput}
                                                <tr>
                                                    <td className="text-left"><b>Total LP token farms</b></td>
                                                    <td></td>
                                                    <td></td>                                            
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td className="text-right"><b>{this.props.outputNumber(this.props.farmsValue/1e6,0)}</b></td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>  
                                    </div>
                                    :''}
                                </div>
                            </div>
                        </div>
                        <div className="col-0 col-xl-1"></div>
                    </div>
                    
                </div>
             


            
         );
    }
}
 
export default Portfolio;