import '../styles/sidebar.css';

import { Modal} from "react-bootstrap";
import { Sidebar, Menu, MenuItem, SubMenu,SideBarFooter, MenuItemFR } from 'react-pro-sidebar';
import {Component} from 'react';
import {Button} from "react-bootstrap";
import logo from '../img/graphics/ISSUAA_Logo.png';
import dashboard from '../img/icons/Dashboard.png';
import portfolio from '../img/icons/Portfolio.png';
import mint from '../img/icons/Mint.png';
import pool from '../img/icons/Pool.png';
import farm from '../img/icons/Farm.png';
import trade from '../img/icons/Trade.png';
import redeem from '../img/icons/Redeem.png';
import governance from '../img/icons/Goverance.png';
import blog from '../img/icons/Blog.png';
import github from '../img/github_white.png';
import discord from '../img/discord_white.png';
import twitter from '../img/twitter_white.png';
import telegram from '../img/telegram_white.png';
import TagIcon from '@mui/icons-material/Tag';
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import CopyrightIcon from '@mui/icons-material/Copyright';
import PolicyIcon from '@mui/icons-material/Policy';
import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';

interface Props {
    icon?: 'hide',
    label?: string,
    balance?: 'show'
  }

const SidebarElement = (props) => {
    const [showDocs, setShowDocs] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const [showEasyFarm, setShowEasyFarm] = useState(false);
    const [IDTConversionPeriod, SetIDTConversionPeriod] = useState(false);
    const [disclaimerModalOpen,setDisclaimerModalOpen] = useState(false);
    
    
    
    const handleChangeView = (_page) => {
      props.changeView(_page);
    };

    const openDisclaimerModal = () => {
        setDisclaimerModalOpen(true)
    }
    const closeDisclaimerModal = () => {
        setDisclaimerModalOpen(false)
    }
  
    const collapseSidebar= () =>{
        if (collapsed === false) {
            setCollapsed(true)
        }
        else{
            setCollapsed(false)
        }
        
    }
    return (
    <div className="" style={{ display: 'flex', minHeight: '100vh' }}>
        <Modal show={disclaimerModalOpen} onHide={closeDisclaimerModal}>
            <Modal.Header className="border" closeButton>
                <Modal.Title>Legal notice</Modal.Title> 
            
            </Modal.Header>
            
            <Modal.Body className="" style={{
              maxHeight: 'calc(100vh - 210px)',
              overflowY: 'auto'
             }} 
            >
                <div>
                  This website provides information and content of general nature about ISSUAA as a decentralized finance protocol (ISSUAA protocol) and as a decentralized autonomous organization (ISSUAA DAO). 
                    
                </div>
                <div>
                  The information and the content of this website may be wrong, subject to change or incomplete and may not be updated. You should not rely on this website or on any of the content therein for any kind of investment decision or for legal advice, financial advice, investment advice, or any kind of advice. 
                    
                </div>
                <div>
                  You always act at your own risk with respect to the content of this website and the ISSUAA protocol. In no way are the owners of, or contributors to the ISSUAA protocol, the ISSUAA DAO and to this website responsible for the actions, decisions, or other behavior taken or not taken by you in connection with this website or your potential interaction with the ISSUAA protocol. 
                    
                </div>
                <div>
                  Please note that the ISSUAA protocol is operated by the ISSUAA DAO and you are solely responsible for compliance with all laws that may apply to you and your use of the ISSUAA protocol and this website. 
                </div>
                <div>
                  Cryptocurrencies and blockchain technologies have been the subject of scrutiny by various regulatory bodies across the globe. 
                </div>
                <div>
                  ISSUAA DAO and its contributors make no representation regarding the application to your use of the ISSUAA protocol or this website of any laws, including, without limitation, those related to investments, tax, gaming, options, derivatives, or securities. Depending on the jurisdiction, the use of the ISSUAA protocol may be restricted. 
                  
                </div>
                <div>
                  You agree that ISSUAA DAO and its contributors are not responsible for determining whether or which laws may apply to you and your use of the ISSUAA protocol and this website. 
                </div>
                <div>
                  The ISSUAA DAO may restrict the use of the ISSUAA protocol to citizens and residents of certain jurisdictions. Particular restrictions apply for US residents who are strictly prohibited from purchasing, minting, farming or staking crypto assets using the ISSUAA protocol. 
                </div>
                <div>
                  Further, restricted persons should not interact with the ISSUAA protocol at any time. By interacting with any smart contract of the ISSUAA protocol you expressly and unconditionally affirm that you are not a resident of the US. 
                </div>
                <div>
                  All rights reserved. No guarantees, warranties or whatsoever given. <b>DeFi tools are not toys. Do your own research.</b>
                </div>

              </Modal.Body>
                        
          </Modal>
        <Sidebar
            className = "sideBar"
            collapsed={collapsed} 
            collapsedWidth="80px"
            backgroundColor= 'rgba(0, 0, 0, 0.5) !important'
            style={{
                borderWidth: "0px",
                fontSize: "1vw",
                textTransform: "uppercase",
              }}
            >
            
                
            <Menu className="text-white"
                menuItemStyles={{
                    button: ({ level, active, disabled }) => {
                      if (level === 0) {
                        return {
                          color: disabled ? "#eee" : "#ffffff",
                          backgroundColor: active ? "#000000" : undefined,
                          "&:hover": {
                             backgroundColor: "#335B8C !important",
                             color: "white !important",
                             borderRadius: "8px !important"
                           },
                        };
                      }
                    },
                  }}
            >
                <MenuItem
                    icon={<MenuOutlinedIcon />}
                    onClick={() => {
                    collapseSidebar();
                    }}
                    style={{ }}
                >
                </MenuItem>
                <div className="pl-3 pt-1 pb-5">
                    {collapsed ? 
                    <span
                    style ={{
                        fontFamily: "PosteramaRegular",
                        letterSpacing: "0.4rem",
                        fontSize: "1.5vw",
                        color:"white",
                    }}
                    >
                        <img className="" src={logo} alt="ISSUAA" width="50px"/>
                    </span>
                    :
                    <span
                    style ={{
                        fontFamily: "PosteramaRegular",
                        letterSpacing: "0.4rem",
                        fontSize: "1.5vw",
                        color:"white",
                    }}
                    >
                        <img className="" src={logo} alt="ISSUAA" width="50px"/>
                        <span className="pl-2">ISSUAA</span>
                    </span>
                    }
                </div>
                
                
                {props.fullProtocolIntegration ?
                    <Link to="/">
                        <span className="p-0 m-0 w-100" type="button" onClick={() =>handleChangeView('mainpage')} >
                            <MenuItem icon={<img src={dashboard} alt="Dashboard" width="30px" />}>
                                <div className="" style={{}} >Dashboard</div>
                            </MenuItem>
                        </span>
                    </Link>
                    :
                    ''
                }
                {props.fullProtocolIntegration ?
                    <Link to="/portfolio">
                        <span className="p-0 m-0 w-100" type="button" onClick={() =>handleChangeView('portfolio')} >
                            <MenuItem icon={<img className="" src={portfolio} alt="Portfolio" width="30px"/>}>
                                <div className="" style={{}}>Portfolio</div>
                            </MenuItem>
                        </span>
                    </Link>
                    :
                    ''
                }
                {props.fullProtocolIntegration ?
                    <Link to="/mint">
                        <span className="p-0 m-0 w-100" type="button" onClick={() =>handleChangeView('factory')} >
                            <MenuItem icon={<img className="" src={mint} alt="Mint assets" width="30px"/>}>
                                <div className="" style={{}}>Mint</div>
                            </MenuItem>
                        </span>
                    </Link>
                    :
                    ''
                }

                {props.fullProtocolIntegration ?
                    <Link to="/pool">
                        <span className="p-0 m-0 w-100" type="button" onClick={() =>handleChangeView('pool')} >
                            <MenuItem icon={<img className="" src={pool} alt="Pool" width="30px"/>}>
                                <div className="" style={{}}>Pool</div>
                            </MenuItem>
                        </span>
                    </Link>
                    :
                    ''
                }
                {props.fullProtocolIntegration && props.farmActive ?
                    <Link to="/farm">
                        <span className="p-0 m-0 w-100" type="button" onClick={() =>handleChangeView('farm')} >
                            <MenuItem icon={<img className="" src={farm} alt="Dashboard" width="30px"/>}>
                                <div className="" style={{}}>Farm</div>
                            </MenuItem>
                        </span>
                    </Link>
                    :
                    ''
                }
                {props.fullProtocolIntegration ?
                    <Link to="/trade">
                        <span className="p-0 m-0 w-100" type="button" onClick={() =>handleChangeView('tradeassets')} >
                            <MenuItem icon={<img className="" src={trade} alt="Dashboard" width="30px"/>}>
                                <div className="" style={{}}>Trade</div>
                            </MenuItem>
                        </span>
                    </Link>
                    :
                    ''
                }
                {props.fullProtocolIntegration ?
                    <Link to="/redeem">
                        <span className="p-0 m-0 w-100" type="button" onClick={() =>handleChangeView('burnassets')} >
                            <MenuItem icon={<img className="" src={redeem} alt="Dashboard" width="30px"/>}>
                                <div>Redeem</div>
                            </MenuItem>
                        </span>
                    </Link>
                    :
                    ''
                }
                {props.fullProtocolIntegration ?
                    <Link to="/governance">
                        <span className="p-0 m-0 w-100" type="button" onClick={() =>handleChangeView('governance')} >
                            <MenuItem icon={<img className="" src={governance} alt="Dashboard" width="30px"/>}>
                                <div>Governance</div>
                            </MenuItem>
                        </span>
                    </Link>
                    :
                    ''
                }
                <a href="https://issuaa.medium.com/" target="_blank" rel="noreferrer">
                        <MenuItem icon={<img src={blog} alt="Blog" width="30px"/>}> Blog </MenuItem>
                </a>
                
                <div>&nbsp;</div>
                    
                    {props.chainName === 'Berachain Artio' & collapsed !== true ? 
                        <Link to="/test">
                            <div className="w-100 pr-4 pl-4 pb-2">
                                <Button size="sm" variant="accent" className="w-100 text-black menuButton" onClick={() => handleChangeView('test')}>Test ETH / USD</Button>
                            </div>
                        </Link>
                        :
                        ''
                    }
                    
                    {(props.chainName === 'Scroll' || props.chainName === 'Berachain Artio')  & collapsed !== true ? 
                        <div className="w-100 pr-4 pl-4 pb-2">
                            <Button size="sm" variant="accent" className="w-100 text-black menuButton">{props.chainName}</Button>
                        </div>
                        :
                        ''
                    }

                    {(props.chainName === 'Scroll' || props.chainName === 'Berachain Artio') && typeof props.GovernanceTokenTotalBalanceAllChains !== "undefined" && props.IDTBalanceWei > 0 == 0  & collapsed !== true ?
                        <Link to="/ISS">
                            <div className="w-100 pr-4 pl-4 pb-2">
                            <Button size="sm" variant="fuchsia" className="w-100 text-black menuButton" onClick={() =>handleChangeView('governancetoken')}>{props.outputNumber(parseFloat(props.GovernanceTokenTotalBalanceAllChains),0)} ISS</Button>
                        </div>
                        </Link>
                        :
                        ''
                    }
                    {(props.chainName === 'Scroll'  || props.chainName === 'Berachain Artio') && typeof props.GovernanceTokenTotalBalanceAllChains !== "undefined" && props.IDTBalanceWei > 0  & collapsed !== true ? 
                        <Link to="/IDT">
                            <div className="w-100 pr-4 pl-4 pb-2">
                                <Button size="sm" variant="fuchsia" className="w-100 text-black menuButton" onClick={() =>handleChangeView('idtConversion')}>IDT - ISS Conversion</Button>
                            </div>
                        </Link>
                        :
                        ''
                    }
                <div className="testCase">
                    <SubMenu
                        icon={<TagIcon/>}
                        label="Socials"
                       
                    >
                        <a href="https://discord.gg/ttu8vEQM6G" target="_blank" rel="noreferrer">
                            <MenuItem icon={<img src={discord} alt="Discord" width="30px"/>}>
                            Discord
                            </MenuItem>
                        </a>
                        <a href="https://t.me/issuaa_main" target="_blank" rel="noreferrer">
                            <MenuItem icon={<img src={telegram} alt="Telegram" width="30px"/>}> Telegram </MenuItem>
                        </a>
                        <a href="https://github.com/issuaa/issuaa-contracts" target="_blank" rel="noreferrer">
                            <MenuItem icon={<img src={github} alt="Github" width="30px"/>}> Github </MenuItem>
                        </a>
                        <a href="https://twitter.com/issuaaOfficial" target="_blank" rel="noreferrer">
                            <MenuItem icon={<img src={twitter} alt="X" width="30px"/>}> X </MenuItem>
                        </a>
                    </SubMenu>    
                </div>
               <span className="p-0 m-0 w-100" type="button" onClick={() =>openDisclaimerModal()} >
                    <MenuItem icon={<PolicyIcon />}>
                        Legal
                    </MenuItem>
                </span>
                <div className="pl-4 pt-5 pt-1 pb-5">
                    {collapsed ? 
                    <span
                    style ={{
                        fontFamily: "PosteramaRegular",
                        letterSpacing: "0.4rem",
                        fontSize: "0.75em",
                        color:"white",
                    }}
                    >
                        
                    </span>
                    :
                    <span
                    style ={{
                        fontFamily: "PosteramaRegular",
                        letterSpacing: "0.4rem",
                        fontSize: "0.75vw",
                        color:"white",
                    }}
                    >
                        <CopyrightIcon />
                        <span className="pl-2">ISSUAA 2024</span>
                    </span>
                    }
                </div>
                
            </Menu>
        </Sidebar>
    </div>
    )
    }
    export default SidebarElement;