import '../styles/market.css';
import React, { Component } from 'react';
import { Modal} from "react-bootstrap";
import arrowDown from '../img/arrow-down.png';
import doubleArrow from '../img/doubleArrow.png';
import Zoom from '@successtar/react-reveal/Zoom';
import { Gear } from 'react-bootstrap-icons';
class Market extends Component {
    
    constructor(props){
        super(props)
        this.state = { 
            assets: [],
            filteredAssets: [],
            selectedAsset: "Select asset:",
            selectedAssetAddress: '',
            selectedAssetBalance: 0,
            chooseAssetModalOpen: false,
            selectedAssetPrice: 0,
            slectedAssetVolume: 0,
            selectedUSDCVolume: 0,
            priceDataVisible: false,
            slippage: 0.01,
            approvalButtonVisible: false,
            sellPartVisible: false,
            buyPartVisible: true,
            style1: "col text-center text-light py-2",
            style2: "col text-center bg-darkAccent text-light py-2",
            priceImpactBuy:0,
            sellButtonVisible: false,
            buyButtonVisible: false,
            warningButtonVisible: false,
            warningButtonMessage: "warning",

        }
    }

    async componentDidMount() {
        this.setState({
            assets: ['wait'],
            USDCBalance: this.props.USDCBalance,
        });
        //check if the USDC Approval button needs to be shown
        let approvalGiven = await this.checkApproval(this.props.USDC_Address, this.props.MarketRouter_Address)
        if (approvalGiven === true) {
            this.setState({"USDApprovalButtonVisible":false})
            
        }
        else {
            this.setState({"USDApprovalButtonVisible":true})

        }
    };

    roundDown = (n,d) => {
        n = Math.floor(n*(10**d))
    
        n = n/(10**d)
        return n
    }

    openSettingsModal = () => this.setState({settingsModalOpen: true})
    closeSettingsModal = () => this.setState({settingsModalOpen: false})
    checkSlippageInput = () => {
        console.log("Checking slippage input")
        try{
            let slippage = document.getElementById('slippage').value * 100
            console.log(slippage)
            if (isNaN(slippage)) {
                this.setState({wrongSlippageInputMessage: true})        
                return false
            }
            if (slippage < 0 || slippage > 5000) {
                this.setState({wrongSlippageInputMessage: true})
                this.setState({highSlippageInputMessage: false})         
                return false
            }
            if (slippage > 500 && slippage < 5000) {
                this.setState({wrongSlippageInputMessage: false})
                this.setState({highSlippageInputMessage: true})        
                return true
            }
            this.setState({wrongSlippageInputMessage: false})
            this.setState({highSlippageInputMessage: false})  
            return (true)
            }
            catch{
                return (false)
            }
    }

    saveSettingsSlippage=async()=>{
        let check = this.checkSlippageInput()
        if (check) {
            let slippage = document.getElementById('slippage').value * 100
            await this.props.saveSlippagePreference(slippage)
                       
        }
    }

    checkTrxTimeInput = () => {
        console.log("Checking trx time input")
        try{
            let trxTime = document.getElementById('trxTime').value
            console.log(trxTime)
            if (isNaN(trxTime)) {
                this.setState({wrongTrxTimeInputMessage: true})        
                return false
            }
            if (trxTime <0 || trxTime > 600) {
                this.setState({wrongTrxTimeInputMessage: true})    
                return true
            }
            this.setState({wrongTrxTimeInputMessage: false})
            return (true)
            }
            catch{
                return (false)
            }
    }

    

    saveSettingsTrxTime=async()=>{
        let check = this.checkTrxTimeInput()
        console.log(check)
        if (check) {
            let trxTime = document.getElementById('trxTime').value
            await this.props.saveTrxTimePreference(trxTime)
            
        }
    }

    openChooseAssetModal=()=>{
        let assets = [];
        let assetBalances = {};
        let assetAddresses = {};
        assets.push(["ISS",this.props.GovernanceTokenBalance,"ISSUAA Protocol Token"]);
        assetBalances["ISS"]= this.props.GovernanceTokenBalance;
        assetAddresses["ISS"] = this.props.GovernanceToken_Address;

        for (let key in this.props.assetDetails) {
            console.log(key)
            console.log(this.props.assetDetails[key])
            assets.push([key,this.props.assetDetails[key]['tokenBalance1'],this.props.assetDetails[key]['name']])
            assetBalances[key] = this.props.assetDetails[key]['tokenBalance1']
            assets.push(["i"+key,this.props.assetDetails[key]['tokenBalance2'],"short".concat(this.props.assetDetails[key]['name'])])
            assetBalances["i"+key] = this.props.assetDetails[key]['tokenBalance2']

            assetAddresses[key] = this.props.assetDetails[key]['Token1']
            assetAddresses["i"+key] = this.props.assetDetails[key]['Token2']
        }
        
        this.setState({assets:assets})
        this.setState({filteredAssets:assets})
        this.setState({assetBalances:assetBalances})
        this.setState({assetAddresses})
        this.setState({ chooseAssetModalOpen: true })  
        console.log(this.state.assets)   
    };
    
    closeChooseAssetModal = () => this.setState({ chooseAssetModalOpen: false });

    openChooseAssetModalSell=()=>{
        let assets = [];
        let assetBalances = {};
        let assetAddresses = {};
        if (this.props.GovernanceTokenBalance > 0.001) {
            assets.push(["ISS",this.props.GovernanceTokenBalance,"ISSUAA Protocol Token"]);
            assetBalances["ISS"]= this.props.GovernanceTokenBalance;
            assetAddresses["ISS"] = this.props.GovernanceToken_Address;
        }
        else {
            console.log(this.props.GovernanceTokenBalance)
        }
        
        for (let key in this.props.assetDetails) {
            console.log(key)
            console.log(this.props.assetDetails[key])
            if (this.props.assetDetails[key]['tokenBalance1']>0.00001){
                assets.push([key,this.props.assetDetails[key]['tokenBalance1'],this.props.assetDetails[key]['name']])
                assetBalances[key] = this.props.assetDetails[key]['tokenBalance1']
            }
            if (this.props.assetDetails[key]['tokenBalance2']>0.00001){
                assets.push(["i"+key,this.props.assetDetails[key]['tokenBalance2'],"short".concat(this.props.assetDetails[key]['name'])])
                assetBalances["i"+key] = this.props.assetDetails[key]['tokenBalance2']
            }

            assetAddresses[key] = this.props.assetDetails[key]['Token1']
            assetAddresses["i"+key] = this.props.assetDetails[key]['Token2']
        }
        
        this.setState({assets:assets})
        this.setState({filteredAssets:assets})
        this.setState({assetBalances:assetBalances})
        this.setState({assetAddresses})
        this.setState({ chooseAssetModalOpen: true })  
        console.log(this.state.assets)   
    };
    
    closeChooseAssetModal = () => this.setState({ chooseAssetModalOpen: false });
    
    filterAssets(){
        let filteredAssets =[];
        let searchTerm = document.getElementById('search').value.toLowerCase()
        for (let i = 0; i < this.state.assets.length; ++i) {
            if (this.state.assets[i][2].toLowerCase().includes(searchTerm) || this.state.assets[i][0].toLowerCase().includes(searchTerm)){
                filteredAssets.push(this.state.assets[i])
            }
            
        }
        this.setState({filteredAssets})

    }
    listAssets() {
        let assetOptions = this.state.filteredAssets.map((element,index) =>
                <div key={index} className="assetSelectList" role="button" onClick={()=>this.selectAsset(element[0])}>
                    <div className="row">
                        <div className="col-3"><b>{element[0]}</b></div>
                        <div className="col text-right"><b>{element[2]}</b></div>
                    </div>
                    <div className="row">
                        <div className="col">Balance: {this.props.outputNumber(element[1],6)}</div>
                    </div>
                </div>
        );
        return(assetOptions)
    }
    
    checkAllowances = async(_tokenAddress) =>{
        let tokenContract = new this.props.web3.eth.Contract(this.props.ERC20_ABI,_tokenAddress)
        let allowanceToken = await tokenContract.methods.allowance(this.props.address, this.props.MarketRouter_Address).call()
        this.setState({allowanceToken: parseInt(allowanceToken)})
        let USDCContract = new this.props.web3.eth.Contract(this.props.ERC20_ABI,this.props.USDC_Address)
        let allowanceUSDC = await USDCContract.methods.allowance(this.props.address, this.props.MarketRouter_Address).call()
        this.setState({allowanceUSDC: parseInt(allowanceUSDC)})
        console.log(this.state.allowanceToken)
        console.log(this.state.allowanceUSDC)
        console.log(this.state.USDCAmountIn)
    }
    
    selectAsset = async(asset) =>{
        this.setState({"selectedAsset":asset});
        await this.setState({"selectedAssetAddress":this.state.assetAddresses[asset]})
        this.setState({"selectedAssetBalance": this.state.assetBalances[asset]});
        await this.setState({"selectedAssetAddress":this.state.assetAddresses[asset]})
        this.checkAllowances(this.state.assetAddresses[asset])
        this.closeChooseAssetModal();
        
        let pair = await this.props.MarketFactory.methods.getPair(this.state.selectedAssetAddress,this.props.USDC_Address).call()
        await this.setState({"selectedLPPairAddress": pair});
        let MarketPair = new this.props.web3.eth.Contract(this.props.MarketPair_ABI,pair)
        let balanceWEI = await MarketPair.methods.balanceOf(this.props.address).call()
        var balance = parseFloat(this.props.web3.utils.fromWei(balanceWEI.toString(), 'ether'))
        let totalSupplyWEI = await MarketPair.methods.totalSupply().call();
        let reserves = await MarketPair.methods.getReserves().call();
        let token0 = await MarketPair.methods.token0().call();
        let token1 = await MarketPair.methods.token1().call();
        let kFactor = reserves[0] * reserves[1];
        this.setState({kFactor})
        this.setState({reserves0:reserves[0]})
        this.setState({reserves1:reserves[1]})
        this.setState({token0})
        this.setState({token1})
        this.setState({token1Balance: balanceWEI})
        console.log(reserves)
        let token1Ratio = parseInt(totalSupplyWEI) / parseInt(reserves[0])
        let token2Ratio = parseInt(totalSupplyWEI) / parseInt(reserves[1])
        this.setState({token1Ratio});
        this.setState({token2Ratio});
        let tokenPrice
        if (token0 === this.props.USDC_Address) {
            console.log("DEBUGING")
            tokenPrice = parseInt(reserves[0])/parseInt(reserves[1])
        }
        else{
            tokenPrice = parseInt(reserves[1])/parseInt(reserves[0])
        }
        var tokenPriceUSD = tokenPrice * (10**(18-parseInt(this.props.USDDecimals)))
        this.setState({tokenPrice})
        this.setState({tokenPriceUSD})
        console.log(tokenPrice)
        console.log(balance)
        console.log(token1Ratio)
        

        //check if the Approval button needs to be shown
        let approvalGiven = await this.checkApproval(this.state.selectedAssetAddress, this.props.MarketRouter_Address)
        console.log(approvalGiven)
        if (approvalGiven === true) {
            this.setState({"approvalButtonVisible":false})
            
        }
        else {
            this.setState({"approvalButtonVisible":true})

        }

        // set the input to zero and calculate freshly
        if (this.state.sellPartVisible) {
            document.getElementById('assetAmountIn').value = 0;
            this.calculateTradeResult()
        }
        else {
            document.getElementById('USDCAmountIn').value = 0;
            this.calculateBuyResult();
        }

        console.log(this.state)
        this.setState({priceDataVisible:false})
        
    }

    calculateTradeResult = async() =>{
        if (this.state.selectedAssetAddress === ''){return}
        this.checkAllowances(this.state.selectedAssetAddress)
        var AssetAmountIn = parseFloat(document.getElementById('assetAmountIn').value)*1e18
               
        // Check if the input is >0
        if (AssetAmountIn < 0){
            AssetAmountIn = this.state.AssetAmountIn
            document.getElementById('assetAmountIn').value = AssetAmountIn/1e18
        }
        
        // Check if there is enough balance
        console.log("Tokenbalance:", this.state.selectedAssetBalance)
        console.log("Amount chosen: ", AssetAmountIn)
        if (AssetAmountIn > parseFloat(this.state.selectedAssetBalance)*1e18) {
            console.log("Not enough balance");
            this.setState({sellButtonVisible:false});
            this.setState({warningButtonVisible:true});
            this.setState({warningButtonMessage:"Balance too low"});
        }
        else {
            this.setState({sellButtonVisible: true})
            this.setState({warningButtonVisible:false})
        }        

        
        var USDCPayoutAmount
        var liquidityProviderFee
        var actualPrice
        if (this.state.token0 === this.props.USDC_Address) {
            
            let newTokenReserves = parseInt(this.state.reserves1) + AssetAmountIn
            let newUSDCPoolBalance = Number(this.state.kFactor) / newTokenReserves
            USDCPayoutAmount = (parseInt(this.state.reserves0) - newUSDCPoolBalance) * 0.997
            liquidityProviderFee = (parseInt(this.state.reserves0) - newUSDCPoolBalance) * 0.003
            actualPrice = USDCPayoutAmount / AssetAmountIn
            if ( isNaN(actualPrice)){
                document.getElementById('USDCPayoutAmount').value = ''
                return
            }
            console.log(actualPrice)
            document.getElementById('USDCPayoutAmount').value = (USDCPayoutAmount/(10**(this.props.USDDecimals))).toFixed(2)
        }
        else {
            let newTokenReserves = parseInt(this.state.reserves0) + AssetAmountIn
            let newUSDCPoolBalance = Number(this.state.kFactor) / newTokenReserves
            USDCPayoutAmount = (parseInt(this.state.reserves1) - newUSDCPoolBalance) * 0.997
            liquidityProviderFee = (parseInt(this.state.reserves1) - newUSDCPoolBalance) * 0.003
            actualPrice = USDCPayoutAmount / AssetAmountIn
            if ( isNaN(actualPrice)){
                document.getElementById('USDCPayoutAmount').value = ''
                return
            }
            console.log(actualPrice)
            document.getElementById('USDCPayoutAmount').value = (USDCPayoutAmount/(10**(this.props.USDDecimals))).toFixed(2)
        }
        this.setState({USDCPayoutAmount});
        let USDCPayoutAmountMin = USDCPayoutAmount * (1-parseFloat(this.state.slippage))
        this.setState({actualPrice})
        this.setState({USDCPayoutAmountMin});
        this.setState({AssetAmountIn});
        this.setState({liquidityProviderFee});
        console.log(actualPrice)
        
        let priceImpact = ((actualPrice - parseFloat(this.state.tokenPrice))/parseFloat(this.state.tokenPrice))*-100
        console.log("Price impact",priceImpact)
        this.setState({priceImpact})
        if (priceImpact>0){this.setState({priceDataVisible:true}) } else {this.setState({priceDataVisible:false}) }  
        //this.setState({priceDataVisible:true}) 
    }

    calculateSellResultUSD = async() =>{
        if (this.state.selectedAssetAddress === ''){return}
        this.checkAllowances(this.state.selectedAssetAddress)
        var USDCPayoutAmount = parseFloat(document.getElementById('USDCPayoutAmount').value)*(10**(this.props.USDDecimals))
        
        // Check if the input is >0
        if (USDCPayoutAmount < 0){
            USDCPayoutAmount = this.state.USDCPayoutAmount // CHECK
            document.getElementById('USDCPayoutAmount').value = USDCPayoutAmount/-(10**this.props.USDDecimals)
        }
        
        var liquidityProviderFee
        var actualPrice
        var AssetAmountIn

        if (this.state.token0 === this.props.USDC_Address) {
            let newUSDCPoolBalance = parseInt(this.state.reserves0) - USDCPayoutAmount 
            let newTokenReserves = Number(this.state.kFactor) / newUSDCPoolBalance
            AssetAmountIn = -(Number(this.state.reserves1) - Number(newTokenReserves))/0.997
            document.getElementById('assetAmountIn').value = (AssetAmountIn/(1000000000000000000)).toFixed(9)
            liquidityProviderFee = (parseInt(this.state.reserves0) - newUSDCPoolBalance) * 0.003
            actualPrice = USDCPayoutAmount / AssetAmountIn
            
        }
        else {
            let newUSDCPoolBalance = parseInt(this.state.reserves1) - USDCPayoutAmount 
            let newTokenReserves = Number(this.state.kFactor) / newUSDCPoolBalance
            AssetAmountIn = -(Number(this.state.reserves0) - Number(newTokenReserves))/0.997
            document.getElementById('assetAmountIn').value = (AssetAmountIn/(1000000000000000000)).toFixed(9)
            liquidityProviderFee = (parseInt(this.state.reserves1) - newUSDCPoolBalance) * 0.003
            actualPrice = USDCPayoutAmount / AssetAmountIn
        }

        // Check if there is enough balance
        console.log("USD balance:", this.props.USDCBalance)
        console.log("Amount choosen: ", AssetAmountIn)
        if (AssetAmountIn > parseFloat(this.state.selectedAssetBalance)*1000000000000000000) {
            console.log("Not enough balance");
            this.setState({sellButtonVisible:false});
            this.setState({warningButtonVisible:true});
            this.setState({warningButtonMessage:"Balance too low"});
        }
        else {
            this.setState({sellButtonVisible: true})
            this.setState({warningButtonVisible:false})
        }

        this.setState({USDCPayoutAmount});
        let USDCPayoutAmountMin = USDCPayoutAmount * (1-parseFloat(this.state.slippage))
        this.setState({USDCPayoutAmountMin});
        this.setState({AssetAmountIn});
        this.setState({liquidityProviderFee});
        this.setState({actualPrice})
        console.log(this.state.actualPrice)
        console.log(parseFloat(this.state.tokenPrice))
        let priceImpact = ((actualPrice - parseFloat(this.state.tokenPrice))/parseFloat(this.state.tokenPrice))*-100
        console.log("Price impact",priceImpact)
        this.setState({priceImpact})
        if (priceImpact>0){this.setState({priceDataVisible:true}) } else {this.setState({priceDataVisible:false}) }  
        //this.setState({priceDataVisible:true}) 
    }

    calculateBuyResult = async() =>{
        if (this.state.selectedAssetAddress === ''){return}
        this.checkAllowances(this.state.selectedAssetAddress)
        // Check if the input is greater zero
        var USDCAmountIn = parseFloat(document.getElementById('USDCAmountIn').value)*(10**this.props.USDDecimals)
        if (USDCAmountIn < 0){
            USDCAmountIn = this.state.USDCAmountIn
            document.getElementById('USDCAmountIn').value = USDCAmountIn/(10**this.props.USDDecimals)
        }
        
        // Check if there is enough balance
        console.log("USDbalance:", this.props.USDCBalance)
        console.log("Amount chosen: ", USDCAmountIn)
        if (USDCAmountIn > parseFloat(this.props.USDCBalance)*(10**this.props.USDDecimals)) {
            console.log("Not enough balance");
            this.setState({buyButtonVisible:false});
            this.setState({warningButtonVisible:true});
            this.setState({warningButtonMessage:"Balance too low"});
        }
        else {
            this.setState({buyButtonVisible: true})
            this.setState({warningButtonVisible:false})
        }  

        console.log(USDCAmountIn)
        var TokenPayoutAmount
        var liquidityProviderFee = USDCAmountIn * 0.003
        var actualPrice
        
        if (this.state.token1 === this.props.USDC_Address) {
            let newUSDCPoolBalance = parseInt(this.state.reserves1) + parseInt(USDCAmountIn)
            let newTokenReserves = Number(this.state.kFactor) / newUSDCPoolBalance
            TokenPayoutAmount = (parseInt(this.state.reserves0) - newTokenReserves) * 0.997
            
            actualPrice = USDCAmountIn / TokenPayoutAmount
            if (isNaN(actualPrice)){
                document.getElementById('TokenPayoutAmount').value = ''
                document.getElementById('USDCAmountIn').value = ''
                return
            }
            document.getElementById('TokenPayoutAmount').value = (TokenPayoutAmount/(1000000000000000000)).toFixed(6)
        }
        else if (this.state.token0 === this.props.USDC_Address){
            console.log("TADA")
            let newUSDCPoolBalance = parseInt(this.state.reserves0) + USDCAmountIn
            let newTokenReserves = Number(this.state.kFactor) / newUSDCPoolBalance
            console.log(newTokenReserves)
            TokenPayoutAmount = (parseInt(this.state.reserves1) - newTokenReserves) * 0.997
            actualPrice = USDCAmountIn / TokenPayoutAmount
            if (isNaN(actualPrice)){
                document.getElementById('TokenPayoutAmount').value = ''
                document.getElementById('USDCAmountIn').value = ''
                return
            }
            document.getElementById('TokenPayoutAmount').value = (TokenPayoutAmount/(1000000000000000000)).toFixed(6)
        }
        this.setState({TokenPayoutAmount});
        let TokenPayoutAmountMin = TokenPayoutAmount * (1-parseFloat(this.state.slippage))
        this.setState({TokenPayoutAmountMin});
        this.setState({USDCAmountIn});
        this.setState({liquidityProviderFee});
        this.setState({actualPrice})
        console.log(actualPrice)
        console.log(parseFloat(this.state.tokenPrice))
        let priceImpactBuy = ((actualPrice - parseFloat(this.state.tokenPrice))/parseFloat(this.state.tokenPrice))*100
        console.log("Price impact",priceImpactBuy)
        this.setState({priceImpactBuy})   
        if (priceImpactBuy>0){this.setState({priceDataVisible:true}) } else {this.setState({priceDataVisible:false}) }  
    }

    calculateBuyResultToken = async() =>{
        if (this.state.selectedAssetAddress === ''){return}
        this.checkAllowances(this.state.selectedAssetAddress)
        console.log("Debug")
        // Check if the input is greater zero
        var TokenPayoutAmount = parseFloat(document.getElementById('TokenPayoutAmount').value)*1000000000000000000
        if (TokenPayoutAmount < 0){
            TokenPayoutAmount = this.state.TokenPayoutAmount
            document.getElementById('TokenPayoutAmount').value = TokenPayoutAmount/-1000000000000000000
        }
        var actualPrice
        var USDCAmountIn

        if (this.state.token0 === this.props.USDC_Address) {
            let newTokenReserves = parseInt(this.state.reserves1) - TokenPayoutAmount
            let newUSDCPoolBalance = Number(this.state.kFactor) / newTokenReserves
            USDCAmountIn = (newUSDCPoolBalance - parseInt(this.state.reserves0))/0.997
            actualPrice = USDCAmountIn / TokenPayoutAmount
            document.getElementById('USDCAmountIn').value = (USDCAmountIn/(10**this.props.USDDecimals)).toFixed(2)
        }
        else if (this.state.token1 === this.props.USDC_Address){
            let newTokenReserves = parseInt(this.state.reserves0) - TokenPayoutAmount
            let newUSDCPoolBalance = Number(this.state.kFactor) / newTokenReserves
            USDCAmountIn = ((newUSDCPoolBalance - parseInt(this.state.reserves1)))/0.997
            actualPrice = USDCAmountIn / TokenPayoutAmount
            document.getElementById('USDCAmountIn').value = (USDCAmountIn/(10**this.props.USDDecimals)).toFixed(2)
            
        }

        // Check if there is enough balance
        console.log("USDbalance:", this.props.USDCBalance)
        console.log("Amount chosen: ", USDCAmountIn)
        if (USDCAmountIn > parseFloat(this.props.USDCBalance)*1000000000000000000) {
            console.log("Not enough balance");
            this.setState({buyButtonVisible:false});
            this.setState({warningButtonVisible:true});
            this.setState({warningButtonMessage:"Balance too low"});
        }
        else {
            this.setState({buyButtonVisible: true})
            this.setState({warningButtonVisible:false})
        }  

        var liquidityProviderFee = USDCAmountIn * 0.003
        
        this.setState({TokenPayoutAmount});
        let TokenPayoutAmountMin = TokenPayoutAmount * (1-parseFloat(this.state.slippage))
        TokenPayoutAmountMin = this.roundDown(TokenPayoutAmountMin,14).toFixed(14).replace(/\.?0+$/,"");
        

        this.setState({TokenPayoutAmountMin});
        this.setState({USDCAmountIn});
        this.setState({liquidityProviderFee});
        console.log(actualPrice)
        
        let priceImpactBuy = ((actualPrice - parseFloat(this.state.tokenPrice))/parseFloat(this.state.tokenPrice))*100
        console.log("Price impact",priceImpactBuy)
        this.setState({priceImpactBuy})   
        if (priceImpactBuy>0){this.setState({priceDataVisible:true}) } else {this.setState({priceDataVisible:false}) }  
    }


    approve = async(tokenAddress,approvalAddress) =>{  
        console.log(tokenAddress)
        console.log(approvalAddress)
        let message = "Approving to spend tokens"
        this.props.openMessageBox(message)
        await this.props.approveToken(tokenAddress,approvalAddress)
        return (true)
    };

    approveAccount = async(contractAddress,approvalAmount) =>{  
        console.log("Approving Token",this)
        let message = "Approving to spend tokens"
        this.props.openMessageBox(message)
        const addressTo = this.props.assetFactory._address;
        var amountRaw = this.props.web3.utils.toWei(approvalAmount.toString(), 'ether')
        var amount = this.props.web3.utils.toBN(amountRaw)
        let tokenContract = new this.props.web3.eth.Contract(this.props.ERC20_ABI,contractAddress)
        const allowance = await tokenContract.methods.allowance(this.props.address, addressTo).call()
        if (parseInt(allowance) < parseInt(amount)){
            let value = this.props.web3.utils.toBN(2).pow(this.props.web3.utils.toBN(256)).sub(this.props.web3.utils.toBN(2))
            
            
            try{
                await tokenContract.methods.approve(addressTo,value).send({from: this.props.address})
                .on('receipt', async (receipt) => {
                    console.log(receipt);
                    if (receipt.status === true) {
                        this.props.closeMessageBox();   
                        }
                    else {
                        this.props.closeMessageBox();
                        let message = "Transaction failed"
                        this.props.openMessageBox(message);
                        await this.props.sleep(5000)
                        this.props.closeMessageBox();   

                    }
                })
            }
            catch(e){
                console.log(e['message'])
                if (e['message'].includes('not mined within 50 blocks') !== true) {
                    message = e['message']
                    this.props.openMessageBox(message)
                    await this.props.sleep(5000)
                    this.props.closeMessageBox();
                    return(false)
                }  
            }
            
        }
        this.props.closeMessageBox()
        return (true)
    };

    sell = async() =>{
        console.log("Starting to trade")
        let message = "Selling tokens"
        this.props.openMessageBox(message)

        var amountInRaw = (parseFloat(this.state.AssetAmountIn)).toLocaleString('fullwide', {useGrouping:false})
        var amountIn = this.props.web3.utils.toBigInt(parseInt(amountInRaw))
                
        let amountOutMinRaw = parseInt(this.state.USDCPayoutAmountMin).toLocaleString('fullwide', {useGrouping:false})
        let amountOutMin = this.props.web3.utils.toBigInt(amountOutMinRaw)

        let path = [this.state.selectedAssetAddress,this.props.USDC_Address]
        let to = this.props.address;
        let deadline = Math.round(+new Date()/1000) + (60*10)
        console.log(amountIn)
        console.log(amountOutMin)
        console.log(deadline)
        console.log(path)
        console.log(to)
        let result = await this.props.transactWithContract('marketRouter','swapExactTokensForTokens',[amountIn,amountOutMin,path,to,deadline])
        await this.props.loadUSDBalance();
        await this.props.updateAssetBalanceWithAddress(this.state.selectedAssetAddress);
        await this.props.updatePortfolioValue();
        document.getElementById('assetAmountIn').value = 0;
        let newBalance = parseFloat(this.state.selectedAssetBalance) -parseFloat(this.state.AssetAmountIn/1e18);
        this.setState({selectedAssetBalance: newBalance});
        document.getElementById('USDCPayoutAmount').value = 0;
        document.getElementById('assetAmountIn').value = 0;
        this.setState({priceDataVisible:false})
        this.setState({selectedAsset: "Select asset:"})
        this.setState({selectedAssetBalance: 0})
        this.setState({selectedAssetAddress:''})
        await this.props.updatePortfolioValue()
    }



    buy = async() =>{
        console.log("Starting to buy assets")
        let message = "Buying tokens"
        this.props.openMessageBox(message)
        var amountInRaw = (parseInt(this.state.USDCAmountIn)).toLocaleString('fullwide', {useGrouping:false})
        console.log(amountInRaw)
        var amountIn = this.props.web3.utils.toBigInt(parseInt(amountInRaw))
                
        let amountOutMinRaw = parseInt(this.state.TokenPayoutAmountMin).toLocaleString('fullwide', {useGrouping:false})
        console.log(amountOutMinRaw)
        let amountOutMin = this.props.web3.utils.toBigInt(parseInt(amountOutMinRaw))


        let path = [this.props.USDC_Address,this.state.selectedAssetAddress]
        let to = this.props.address;
        let deadline = Math.round(+new Date()/1000) + (60*10)
        console.log(amountIn)
        console.log(amountOutMin)
        console.log(deadline)
        console.log(path)
        console.log(to)
        let result = await this.props.transactWithContract('marketRouter','swapExactTokensForTokens',[amountIn,amountOutMin,path,to,deadline])
        let newBalance = parseFloat(this.state.TokenPayoutAmount/1e18) + parseFloat(this.state.selectedAssetBalance);
        this.setState({selectedAssetBalance: newBalance});
        await this.props.loadUSDBalance();
        await this.props.updateAssetBalanceWithAddress(this.state.selectedAssetAddress);
        let assetDetails = this.props.assetDetails;
        console.log(assetDetails)
        console.log(assetDetails.length)
        console.log(typeof(assetDetails))
        document.getElementById('USDCAmountIn').value = 0;
        document.getElementById('TokenPayoutAmount').value = 0;
        this.setState({priceDataVisible:false})
        this.setState({selectedAsset: "Select asset:"})
        this.setState({selectedAssetBalance: 0})
        this.setState({selectedAssetAddress:''})
        await this.props.updatePortfolioValue()
        
    }

    checkApproval = async(tokenAddress, approvalAddress) =>{  
        console.log(tokenAddress)
        console.log(approvalAddress)
        let tokenContract = new this.props.web3.eth.Contract(this.props.ERC20_ABI,tokenAddress)
        var amountRaw = 100000000000000000000000000000
        var amount = this.props.web3.utils.toBigInt(amountRaw)
        
        let allowance = await tokenContract.methods.allowance(this.props.address, approvalAddress).call()

        if (parseInt(allowance) < parseInt(amount)){
            return(false)
        }
        else {return (true)}
    };

    approveAccount1 = async(tokenAddress,approvalAddress) =>{  
        
        let message = "Approving to spend tokens"
        this.props.openMessageBox(message)
        
        let tokenContract = new this.props.web3.eth.Contract(this.props.ERC20_ABI,tokenAddress)
        let value = this.props.web3.utils.toBigInt(2**255)
        try{
            await tokenContract.methods.approve(approvalAddress,value).send({from: this.props.address})
            .on('receipt', async (receipt) => {
                console.log(receipt);
                if (receipt.status === true) {
                    this.props.closeMessageBox();   
                    }
                else {
                    this.props.closeMessageBox();
                    let message = "Transaction failed"
                    this.props.openMessageBox(message);
                    await this.props.sleep(5000)
                    this.props.closeMessageBox();   

                }
                   
            })
        }
        catch(e){
            console.log(e['message'])
            if (e['message'].includes('not mined within 50 blocks') !==true) {
                message = e['message']
                this.props.openMessageBox(message)
                await this.props.sleep(5000)
                this.props.closeMessageBox();
                return(false)
            }  
        }
        this.props.closeMessageBox()
        return (true)
    };

    approveMarketRouter = async() =>{
        console.log(this.state.selectedAssetAddress, this.props.MarketRouter_Address)
        let trx_status = await this.approve(this.state.selectedAssetAddress,this.props.MarketRouter_Address)
        if (this.state.selectedAssetAddress != ''){
            this.checkAllowances(this.state.selectedAssetAddress)
        }
        
    }

    approveMarketRouterUSD = async() =>{
        let trx_status = await this.approve(this.props.USDC_Address,this.props.MarketRouter_Address)
        if (this.state.selectedAssetAddress != ''){
            this.checkAllowances(this.state.selectedAsset)
        }
    }

    showSell = async() =>{
        await this.setState({
            style1: "col text-center bg-darkAccent text-light py-2",
            style2: "col text-center text-light py-2",

            sellPartVisible: true,
            buyPartVisible: false,
        })
        document.getElementById('assetAmountIn').value = 0;
        this.calculateTradeResult()
    }

    showBuy = async() =>{
        await this.setState({
            style1: "col text-center text-light py-2",
            style2: "col text-center bg-darkAccent text-light py-2",
            sellPartVisible: false,
            buyPartVisible: true,
            
        })
        document.getElementById('USDCAmountIn').value = 0;
        this.calculateBuyResult();

    }

    setMaxSellBalanceToken = async() =>{
        //document.getElementById('assetAmountIn').value = this.state.selectedAssetBalance;
        document.getElementById('assetAmountIn').value = this.roundDown(this.state.selectedAssetBalance,14).toFixed(14).replace(/\.?0+$/,"");
        this.calculateTradeResult()
    }

    setMaxBuyBalanceUSD = async() =>{
        document.getElementById('USDCAmountIn').value = this.roundDown(this.props.USDCBalance,6).toFixed(14).replace(/\.?0+$/,"");
        this.calculateBuyResult()
    }

    render() { 
        console.log(this.state)
        
        return ( 
            <div className="row w-100">
                <div className="container-fluid m-3">

                    <Modal show={this.state.chooseAssetModalOpen} onHide={this.closeChooseAssetModal}>
                        <Modal.Header className="" closeButton>
                            <Modal.Title>Select a token</Modal.Title>   
                        </Modal.Header>
                        
                        <Modal.Body className="" style={{
                          maxHeight: 'calc(100vh - 210px)',
                          overflowY: 'auto'
                         }} 
                        >
                            <div className="row p-3 pr-3 my-auto">
                                <input className="col w-100 searchField" id="search" placeholder="Search" onChange={() =>this.filterAssets()}></input>
                                
                            </div>
                            <div className="list-group border">
                                {this.listAssets()}
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Modal show={this.state.settingsModalOpen} onHide={this.closeSettingsModal}>
                        <Modal.Header className="border" closeButton>
                            <Modal.Title>Chose your preferences</Modal.Title>   
                        </Modal.Header>
                        <Modal.Body className="bg-tgrey" style={{
                          maxHeight: 'calc(100vh - 210px)',
                          overflowY: 'auto'
                         }} 
                        >
                            <div className="form-row align-items-center mx-3">
                                <div className="col">Slippage tolerance</div>
                                <div className="col col-3 bg-light">
                                    <input className="px-2 form-control input-sm pull-right" type="decimals" id="slippage" onChange={this.saveSettingsSlippage} placeholder={this.props.slippage/100}></input>
                                </div>
                                <div className="col col-2">%</div>
                            </div>
                            {this.state.wrongSlippageInputMessage
                                ?
                                <div className="row pl-3 pt-3 w-100 text-danger ">Please input a valid slippage percentage</div>
                                :
                                ''
                            }
                            
                            {this.state.highSlippageInputMessage
                                ?
                                <div className="row pl-3 pt-3 w-100 text-danger ">Warning. Your transaction may be frontrun</div>
                                :
                                ''
                            }
                            
                            <div>&nbsp;</div>

                            <div className="form-row align-items-center mx-3">
                                <div className="col">Max transaction time</div>
                                <div className="col col-3 bg-light">
                                    <input className="px-2 form-control input-sm pull-right" type="decimals" id="trxTime" onChange={this.saveSettingsTrxTime} placeholder={this.props.trxTime}></input>
                                </div>
                                <div className="col col-2">minutes</div>
                            </div>
                            {this.state.wrongTrxTimeInputMessage
                                ?
                                <div className="row pl-3 pt-3 w-100 text-danger ">Please input a valid transaction time</div>
                                :
                                ''
                            }                           
                        </Modal.Body>
                    </Modal>

                        <div className="row">
                        
                            <div className="col"></div>
                                <div className="col-5">
                                <div 
                                    style ={{
                                        fontFamily: "PosteramaRegular",
                                        letterSpacing: "0.1rem",
                                        fontSize: "3.5vw",
                                        color:"white",
                                        paddingLeft: "2vw",
                                        marginBottom: "2vh",
                                    }}
                                >
                                    Trade
                                </div>
                                <div id="mainBox" className="container text-light p-0 rounded">
                                
                                    <div className="container">
                                        <div className="row">
                                            <div id="mainBoxUpLeft" className={this.state.style1} role="button" onClick={this.showBuy}>Buy</div>
                                            <div id="mainBoxUpRight" className={this.state.style2} role="button" onClick={this.showSell}>Sell</div>
                                        </div>
                                    </div>
                                    
                                    {this.state.sellPartVisible 
                                    ?
                                    <div id="mainBoxDown" className="px-4 py-4">
                                        <div className="mb-3 w-100 text-right">
                                            <Gear className="h4" role="button" onClick={this.openSettingsModal}/>
                                        </div>
                                        <div className="subBox px-4 py-4 ">
                                            <div className="row text-small">
                                                <div className="col align-self-start">Pay</div>
                                                <div role="button" onClick={this.setMaxSellBalanceToken} className="col textBalance">Balance: <span className="tradeBalance">{this.props.outputNumber(this.state.selectedAssetBalance,6)} (Max)</span></div>
                                            </div>

                                            
                                            <div className="row">
                                                <div className="col">
                                                    <input id="assetAmountIn" onChange={() =>this.calculateTradeResult()} className="inputCustom" type="text" lang="en" placeholder="0"/>
                                                </div>
                                                <div className="col text-right my-auto">
                                                    <div className="btn my-auto btn-accent" id="buttonRounded" onClick={this.openChooseAssetModalSell}>
                                                        <div>{this.state.selectedAsset} <img src={arrowDown} alt="switch" height="15"/>   </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="d-flex align-items-center justify-content-center h-100 py-2">
                                                <img src={doubleArrow} alt="switch" height="30"/> 
                                            </div>
                                            
                                        </div>
                                        
                                        <div className="subBox px-4 py-4 ">
                                            <div className="row">
                                                <div className="col align-self-start">Receive</div>
                                                <div className="col textBalance">Balance: {this.props.outputNumber(this.props.USDCBalance,2)}</div>
                                            </div>

                                            
                                            <div className="row">
                                                <div className="col">
                                                    <input id="USDCPayoutAmount" onChange={() =>this.calculateSellResultUSD()} className="inputCustom" type="text" lang="en" placeholder="0"/>
                                                </div>
                                                <div id="tradeBoxText" className="my-auto col text-right">HONEY</div>
                                            </div>
                                        </div>
                                        {this.state.priceDataVisible 
                                            ?
                                            <div className="container  py-4 pr-4 ">
                                                <div className="row text-light">
                                                    <div className="col align-self-start">Price:</div>
                                                    <div className="col align-self-end text-lg-right">HONEY per {this.state.selectedAsset}: {parseFloat(this.state.actualPrice*10e11).toFixed(3)}</div>
                                                </div>
                                            </div>

                                            : ''
                                        }
                                        {this.state.selectedAssetBalance > this.state.allowanceToken && this.state.priceDataVisible
                                        ?
                                            <div className="py-3">
                                                <div className="btn w-100 issuaaButtonPink" onClick={() =>this.approveMarketRouter()}>Approve</div>
                                            </div>
                                        :
                                            ''
                                        }
                                        
                                        {this.state.selectedAssetBalance < this.state.allowanceToken && this.state.priceDataVisible && this.state.warningButtonVisible === false
                                            ?
                                            <div className="py-3">
                                                <div className="btn w-100 issuaaButton" onClick={this.sell}>Trade</div>
                                            </div>
                                            :
                                            ''
                                        }
                                        {this.state.warningButtonVisible
                                            ?
                                            <div className="py-3">
                                                <div deactivated className="btn  issuaaButtonDeactivated w-100">{this.state.warningButtonMessage}</div>
                                            </div>
                                            :
                                            ''
                                        }


                                        {this.state.priceDataVisible 
                                        ?
                                        <div className="subBox px-4 py-4 ">
                                            <div>Minimum to receive: {this.props.outputNumber(parseFloat(this.state.USDCPayoutAmountMin/(10**this.props.USDDecimals)),2)}</div>
                                            <div>Price impact: {this.props.outputNumber(this.state.priceImpact,2)}%</div>
                                            <div>Liquidity Provide Fee: {this.props.outputNumber(parseFloat(this.state.liquidityProviderFee)/(10**this.props.USDDecimals),2)} HONEY</div>
                                        </div>
                                        :
                                        ''
                                        }
                                    </div>
                                    :
                                    ''
                                }


                                    {this.state.buyPartVisible
                                    ?
                                    <div id="mainBoxDown" className="px-4 py-4 border">
                                        <div className="mb-3 w-100 text-right">
                                            <Gear className="h4" role="button" onClick={this.openSettingsModal}/>
                                        </div>
                                        <div className="subBox px-4 py-4 ">
                                            <div className="row">
                                                <div className="col align-self-start">Pay</div>
                                                <div role="button" id="buttonRounded" onClick={this.setMaxBuyBalanceUSD} className="col textBalance">Balance: <span className="tradeBalance">{this.props.outputNumber(parseFloat(this.props.USDCBalance),4)} (Max)</span></div>
                                            </div>

                                            
                                            <div className="row">
                                                <div className="col my-auto">
                                                    <input className="inputCustom" id="USDCAmountIn" onChange={() =>this.calculateBuyResult()}  type="text" lang="en" placeholder="0"/>
                                                </div>
                                                <div className="col my-auto">
                                                    <div id="tradeBoxText" className="my-auto col text-right">HONEY</div>
                                                    </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div className="d-flex align-items-center justify-content-center h-100 py-2">
                                                <img src={doubleArrow} alt="switch" height="30"/>   
                                            </div>
                                            
                                        </div>    

                                        <div id="" className="subBox px-4 py-4">
                                            <div className="row text-small">
                                                <div className="col align-self-start">Receive</div>
                                                <div className="col textBalance">Balance: {this.props.outputNumber(parseFloat(this.state.selectedAssetBalance),4)}</div>
                                            </div>

                                        

                                            <div className="row">
                                                <div className="col">
                                                    <input id="TokenPayoutAmount" onChange={() =>this.calculateBuyResultToken()} className="inputCustom" type="text" lang="en" placeholder="0"/>
                                                </div>
                                                <div className="col text-right my-auto">
                                                    <div className="btn my-auto btn-accent" id="buttonRounded" onClick={this.openChooseAssetModal}>
                                                        <div>{this.state.selectedAsset} <img src={arrowDown} alt="switch" height="15"/>   </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        
                                        
                                        {this.state.priceDataVisible 
                                            ?
                                            <div className="container  py-4 pr-4">
                                                <div className="row text-light">
                                                    <div className="col align-self-start">Price:</div>
                                                    <div className="col align-self-end text-lg-right">HONEY per {this.state.selectedAsset}: {this.props.outputNumber(parseFloat(this.state.actualPrice*10e11),3)}</div>
                                                </div>
                                            </div>

                                            : ''
                                        }
                                        {this.state.USDCAmountIn > this.state.allowanceUSDC && this.state.priceDataVisible
                                        ?
                                            <div className="py-3">
                                                <div className="btn w-100 issuaaButtonPink" onClick={() =>this.approveMarketRouterUSD()}>Approve</div>
                                            </div>
                                        :
                                            ''
                                        }
                                        
                                        {this.state.USDCAmountIn < this.state.allowanceUSDC && this.state.warningButtonVisible === false && this.state.priceDataVisible
                                            ?
                                            <div className="py-3">
                                                <div className="btn w-100 issuaaButton" onClick={this.buy}>Trade</div>
                                            </div>
                                            :
                                            ''
                                        }
                                        {this.state.warningButtonVisible
                                            ?
                                            <div className="py-3">
                                                <div className="btn issuaaButtonDeactivated w-100">{this.state.warningButtonMessage}</div>
                                            </div>
                                            :
                                            ''
                                        }

                                        {this.state.priceDataVisible 
                                        ?
                                        <div className="subBox px-4 py-4 ">
                                            <div>Minimum to receive: {this.props.outputNumber(parseFloat(this.state.TokenPayoutAmountMin/1000000000000000000),6)}</div>
                                            <div>Price impact: {parseFloat(this.state.priceImpactBuy).toFixed(2)}%</div>
                                            <div>Liquidity Provider Trading Fee: {this.props.outputNumber(parseFloat(this.state.liquidityProviderFee/(10**this.props.USDDecimals)),2)} HONEY</div>
                                        </div>
                                        :
                                        ''
                                        }
                                    </div>
                                    :
                                    ''
                                }    

                                </div>
                            </div>


                            <div className="col"></div>
                        </div>
                </div>
                        
                        
            </div>
        );
    }
}
 
export default Market;